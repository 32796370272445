import React from "react";
import styled from "styled-components";
import AGMenu from "../components/AGMenu";
import {
	ListHeader,
	ListTitle,
	MediumContainer,
	Button,
	Modal,
	ModalOverlay,
	ModalClose,
} from "../utils/styled-components-library";
import API from "../utils/API";
import FileInput from "../components/FileInput";
import { Formik, Form } from "formik";
import config from "../config";

const ListRow = styled.div`
	border-bottom: 1px solid #c0c3cb;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
`;

const ListContainer = styled.div`
	text-align: left;
`;

export default class AGDocuments extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ag: null,
			errorUploadMsg: "",
			documents: [],
			selectedDocument: null,
			showDeleteDocModal: false,
			selectedFileNames: [],
		};
	}

	componentDidMount() {
		this.refreshData();
		this.getAgDocuments();
	}

	refreshData() {
		API.get("/agDetails", { agId: this.props.match.params.agId }, (data) => {
			this.setState({
				ag: data,
			});
		});
	}

	getAgDocuments() {
		API.get(
			"/agDocuments",
			{ agId: this.props.match.params.agId, action: "get" },
			(data) => {
				if (data.success) {
					this.setState({ documents: data.documents });
				}
			}
		);
	}

	uploadFiles(files) {
		files.forEach((file, index) => {
			API.post(
				"/agDocuments",
				{
					agId: this.props.match.params.agId,
					documentURL: file,
					documentName: this.state.selectedFileNames[index],
					action: "upload",
				},
				(data) => {
					if (data.success) {
						this.getAgDocuments();
						this.setState({
							errorUploadMsg: "",
						});
					} else {
						this.setState({
							errorUploadMsg: "Impossible d'ajouter les documents.",
						});
					}
				}
			);
		});
	}

	deleteSelectedDoc() {
		API.get(
			"/agDocuments",
			{ docId: this.state.selectedDocument.id, action: "delete" },
			(data) => {
				if (data.success) {
					this.getAgDocuments();
					this.setState({
						errorDeletingMsg: "",
						showDeleteDocModal: false,
						selectedDocument: null,
					});
				} else {
					this.setState({
						errorDeletingMsg: "Impossible de supprimer le document.",
						showDeleteDocModal: false,
						selectedDocument: null,
					});
				}
			}
		);
	}

	onFileInputChange(event) {
		this.setState({
			selectedFileNames: Object.keys(event.target.files).map(
				(x) => event.target.files[x].name
			),
		});
	}

	render() {
		return (
			<>
				<MediumContainer>
					<ListHeader>
						<ListTitle>{this.state.ag && this.state.ag.agName}</ListTitle>
					</ListHeader>
					<AGMenu activeTab="Documents" ag={this.state.ag} {...this.props} />
					<ListContainer>
						{this.state.documents.map((document) => (
							<ListRow key={document.id}>
								<div>
									<a
										href={config.s3BaseUrl + document.docPath}
										target="_blank"
										rel="noreferrer"
									>
										{document.name}
									</a>
								</div>
								<Button
									style={{ backgroundColor: "red", maxWidth: "100px" }}
									onClick={() =>
										this.setState({
											showDeleteDocModal: true,
											selectedDocument: document,
										})
									}
								>
									Supprimer
								</Button>
							</ListRow>
						))}
					</ListContainer>
					{this.state.documents.length === 0 && (
						<h5 style={{ color: "red" }}>
							Aucun document n'a été ajouté pour cette session de vote.
						</h5>
					)}
					<h5 style={{ color: "red" }}>{this.state.errorUploadMsg}</h5>
					<h3>Ajouter un document :</h3>
					<Formik
						initialValues={{ agDocuments: [] }}
						onSubmit={(values, formikBag) => {
							this.uploadFiles(values.agDocuments);
						}}
					>
						{(formikProps) => (
							<Form>
								<FileInput
									name="agDocuments"
									customOnChange={(event) => this.onFileInputChange(event)}
									multiple
								/>
								<Button
									onClick={this.submit}
									style={{ width: 200, margin: 15 }}
									disabled={formikProps.values.agDocuments.length === 0}
								>
									Ajouter
								</Button>
							</Form>
						)}
					</Formik>
				</MediumContainer>

				{this.state.showDeleteDocModal ? (
					<ModalOverlay
						onClick={() => {
							window.location.hash = "";
							this.setState({ showDeleteDocModal: false });
						}}
					>
						<Modal onClick={(e) => e.stopPropagation()}>
							<ModalClose
								onClick={() => {
									window.location.hash = "";
									this.setState({ showDeleteDocModal: false });
								}}
							/>
							<h4>
								Êtes-vous sûr(e) de vouloir supprimer{" "}
								{this.state.selectedDocument.name} ?
							</h4>
							<div style={{ display: "flex", flexDirection: "row" }}>
								<Button
									style={{ backgroundColor: "red", marginRight: "5px" }}
									onClick={() => this.deleteSelectedDoc()}
								>
									Supprimer
								</Button>
								<Button
									onClick={() => {
										window.location.hash = "";
										this.setState({
											showDeleteDocModal: false,
											selectedDocument: null,
										});
									}}
								>
									Annuler
								</Button>
							</div>
						</Modal>
					</ModalOverlay>
				) : null}
			</>
		);
	}
}
