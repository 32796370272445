import styled from "styled-components";


const TableContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`;

const TableTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

export default function TableVoteResults({ resultDisplayType, voteResults, voteType, title, voteOriginalOptionIds }) {
  const getResultLabel = () => {
    if (resultDisplayType === "voteCount") {
      return "Nombre de suffrages exprimés";
    } else if (resultDisplayType === "votePercent") {
      return "Pourcentage de suffrages exprimés";
    } else {
      return "Nombre et pourcentage de suffrages exprimés";
    }
  }

  const getOptionOrder = (optionId) => {
    let optionsOrder;
    if (voteType === 'parityPlurinominalElection') {
      optionsOrder = voteOriginalOptionIds.sort();
    } else {
      optionsOrder = voteResults.map((x) => x.optionId).sort();
    }
    return optionsOrder.findIndex((x) => x === optionId) + 1;
  }

  const getResultValue = (option) => {
    if (resultDisplayType === "voteCount") {
      return option.count || 0;
    } 
    const voteResultPercentage = computeVoteResultPercentage(voteResults, option);    
    if (resultDisplayType === "votePercent") {
      return voteResultPercentage;
    } 
    return `${option.count || 0} (${voteResultPercentage}%)`;
  }

 const computeVoteResultPercentage = (voteResults, option) => {
    // Total de voix
    let total = voteResults.reduce(function (acc, currentResult) {
      if (currentResult.count) {
        return acc + parseFloat(currentResult.count);
      } else return acc;
    }, 0);
    // Pourcentage
    return total === 0 ? '-' : parseFloat((option.count / total) * 100).toFixed(2);
  };

  const getLabel = (voteType, option) => {
    if (voteType === "parityPlurinominalElection") {
      const gender = JSON.parse(option.optionMetadata).gender;
      return gender === "man" ? `Mr ${option.optionName}` : `Mme ${option.optionName}`;
    } else {
      return option.optionType === "voteAbstain"
        && voteType !== "resolutionVote"
          ? "Vote(s) blanc(s)"
          : option.optionName;
    }
  }

  return (
    <TableContainer>
      {title && <TableTitle>{title}</TableTitle>}
      <table style={{ width: "100%" }}>
        <thead style={{ backgroundColor: "#bdc3c7" }}>
          <tr>
            <th>Option n°</th>
            <th>Libellé</th>
            <th>{getResultLabel()}</th>
          </tr>
        </thead>
        <tbody style={{ backgroundColor: "#ecf0f1" }}>
          {voteResults.map((option) => (
            <tr key={option.optionId}>
              <td>
                {getOptionOrder(option.optionId)}
              </td>
              <td>
                {getLabel(voteType, option)}
              </td>
              <td>
                {getResultValue(option)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableContainer>
  )
}