import React from "react";
import {
	Button,
	ModalOverlay,
	Modal,
	ModalClose,
} from "../utils/styled-components-library";

function ConfirmDestructiveModal(props) {
	return (
		<ModalOverlay onClick={() => props.onCloseHandler()}>
			<Modal onClick={(e) => e.stopPropagation()}>
				<ModalClose onClick={() => props.onCloseHandler()} />
				<h4>{props.message}</h4>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<Button
						style={{ backgroundColor: "red", marginRight: "5px" }}
						onClick={() => props.confirmButtonHandler()}
					>
						{props.confirmButtonText}
					</Button>
					<Button onClick={() => props.onCloseHandler()}>Annuler</Button>
				</div>
			</Modal>
		</ModalOverlay>
	);
}

export default ConfirmDestructiveModal;
