import React, { useState } from "react";
import { useField, useFormikContext } from "formik";
import API from "../utils/API";
import styled from "styled-components";
import * as config from "../config";

const StyledInput = styled.input`
  width: 100%;
  border-radius: 2px;
  border: solid 1px #c0c3cb;
  font-family: inherit;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  padding: 10px;
  margin-bottom: 8px;
  box-sizing: border-box;
  cursor: pointer;

  background-image: ${props =>
		props.isLoading ? "url('/img/loading.gif')" : ""};
  background-repeat: no-repeat;
  background-size: 19px;
  background-position: top 12px right 12px;
`;

const FileInput = ({ ...props }) => {
	const { setFieldValue } = useFormikContext();
	const [isLoading, setIsLoading] = useState(false);
	const [field] = useField(props);

	const getPresignedS3URL = async fileBody => {
		console.log("starting upload");
		const response = await API.get("/getPresignedS3Url", {}, data => {
			return fetch(data.url, {
				method: "PUT",
				body: fileBody
			});
		});

		return response.url.split("?")[0].replace(config.s3BaseUrl, "");
	};

	return (
		<StyledInput
			type="file"
			disabled={props.disabled}
			isLoading={isLoading}
			multiple={props.multiple}
			onChange={async e => {
				if (props.customOnChange) props.customOnChange(e);
				setIsLoading(true);
				if (!props.multiple) {
					const fileBody = e.target.files[0];
					const filename = await getPresignedS3URL(fileBody);

					setFieldValue(field.name, filename);
				} else {
					const filenames = [];
					for (const file of e.target.files) {
						filenames.push(await getPresignedS3URL(file));
					}

					setFieldValue(field.name, filenames);
				}

				setIsLoading(false);
				if (props.onLoadingComplete) props.onLoadingComplete();
			}}
		/>
	);
};

export default FileInput;
