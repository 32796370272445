import React from "react";
import "../components/Vote.css";
import styled from "styled-components";
import { Fab } from "@material-ui/core";
import VoteIcon from "./VoteIcon";

const VoteButtonLabel = styled.span`
	font-size: 25px;
	margin-top: 15px;
	font-weight: 500;
	@media (max-width: 500px) {
		font-size: 15px;
	}
`;

export default function VoteButton({
	label,
	colors,
	onClick,
	enabled,
	selected,
	icon,
}) {
	return (
		<div
			style={{
				display: "flex",
				flex: 1,
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				maxWidth: 150,
			}}
		>
			<Fab
				style={{
					backgroundColor: selected ? colors[0] : "white",
					borderColor: enabled ? colors[0] : colors[1],
				}}
				disabled={!enabled}
				onClick={enabled ? onClick : null}
			>
				<VoteIcon
					iconName={icon}
					style={{
						color: selected ? "white" : enabled ? colors[0] : colors[1],
						fontSize: 50,
					}}
					size="large"
				/>
			</Fab>

			<VoteButtonLabel
				style={{
					color: enabled ? colors[0] : colors[1],
					fontSize: label && label.length > 20 ? 12 : "auto",
				}}
			>
				{label}
			</VoteButtonLabel>
		</div>
	);
}
