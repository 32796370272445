import { createMuiTheme } from "@material-ui/core";

export const customTheme = createMuiTheme({
	palette: {
		primary: {
			main: "#1D529C",
		},
	},
	overrides: {
		MuiButton: {
			root: {
				textTransform: "none",
			},
		},
	},
});

export const ProVoteMainTheme = createMuiTheme({
	overrides: {
		MuiButton: {
			root: {
				padding: "5px",
				textTransform: "none",
				fontSize: "16px",
				fontWeight: "400",
				marginTop: 8,
				marginBottom: 8,
			},
			containedPrimary: {
				backgroundColor: "#1D529C",
				"&:hover": {
					backgroundColor: "#1D529C",
				},
			},
		},
		MuiIconButton: {
			colorPrimary: {
				color: "#000",
			},
			colorSecondary: {
				color: "#f44336",
			},
		},
	},
});
