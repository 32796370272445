import React from "react";
import API from "../utils/API";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import config from "../config";

const Container = styled.div`
  padding: 10px;
  max-width: 900px;
  margin: auto;
  position: relative;
`;

const DocumentsContainer = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

const DocumentsTitle = styled.h4`
  color: #7b8bab;
  text-transform: uppercase;
`;

const DocumentsGrid = styled.div`
  display: grid;
  /* One file column in mobile mode (<= 600px) */
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
  /* Five files columns in desktop mode (>= 601px) */
  @media (min-width: 601px) {
    grid-template-columns: repeat(2, 1fr);
  }
  grid-gap: 10px;
  grid-auto-rows: minmax(auto, auto);
`;

const DocumentRef = styled.a`
  display: block;
  background-color: #fafcff;
  padding: 8px;
  border-radius: 3px;
  color: #363636;
  text-decoration: none;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  i {
    margin-right: 10px;
    color: #115ec2;
  }
  span {
    text-decoration: none;
    color: #363636;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &:hover {
    background-color: #b8ceff;
  }
`;

class BrowseDocuments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ag: null,
      documents: []
    };

    this.fetchDatas();
  }

  fetchDatas() {
    API.get(
      "/voteParams",
      {
        voteId: this.props.match.params.voteId
      },
      data => {
        this.setState({
          ag: data.ag
        });

        this.fetchAgDocuments();
      }
    );
  }

  fetchAgDocuments() {
    API.get(
      "/agDocuments",
      { agId: this.state.ag?.agId, action: "get" },
      data => {
        if (data.success) {
          this.setState({ documents: data.documents });
        }
      }
    );
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    }

    return (
      <Container>
        {this.state.documents.length !== 0 ? (
          <DocumentsContainer>
            <DocumentsTitle>Documents relatifs à la session de vote</DocumentsTitle>
            <DocumentsGrid>
              {this.state.documents.map(document => (
                <DocumentRef
                  href={config.s3BaseUrl + document.docPath}
                  target="_blank"
                >
                  <i class="fa fa-file" />
                  <span key={document.id}>{document.name}</span>
                </DocumentRef>
              ))}
            </DocumentsGrid>
          </DocumentsContainer>
        ) : (
          <h5 style={{ color: "grey" }}>
            Aucun document relatif à cette session de vote n'est disponible.
          </h5>
        )}
      </Container>
    );
  }
}

export default withRouter(BrowseDocuments);
