import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import {
	Modal,
	ModalOverlay,
	ModalClose,
} from "../utils/styled-components-library";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import API from "../utils/API";
import Loader from "react-loader-spinner";

const Header = styled.div`
	margin-bottom: 10px;
	font-weight: bold;
	text-align: center;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
	box-sizing: border-box;
	padding: 5px;
	position: relative;

	&:hover {
		background-color: ${(props) =>
			props.isData ? "rgba(0, 0, 0, 0.1)" : "white"};
	}
`;

const RowItem = styled.div`
	padding: 2px;
	width: 20%;
	word-break: break-all;
	text-align: left;
`;

const RowItemHeader = styled(RowItem)`
	font-weight: bold;
	text-align: left;
`;

const RowsWrapper = styled.div`
	max-height: 60vh;
	overflow: auto;
`;

const SearchInput = styled.input.attrs({
	type: "text",
})`
	display: flex;
	flex-direction: row;
	box-sizing: border-box;
	width: 100%;
	padding: 5px 10px;
	font-size: 15px;
	font-family: inherit;
	margin-top: 10px;
`;

const VotersListTabPanel = (props) => {
	const { children, value, index } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`voters-list-tab-panel-${index}`}
			aria-labelledby={`voters-list-tab-${index}`}
		>
			{children}
		</div>
	);
};

const votersListTabs = Object.freeze({
	voters: 0,
	groups: 1,
});

const ProVoteTabs = withStyles({
	indicator: {
		backgroundColor: "#1D529C",
	},
})(Tabs);

const ProVoteTab = withStyles((theme) => ({
	root: {
		"&$selected": {
			fontWeight: "bold",
		},
	},
	selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function VoterList(props) {
	let [voters, setVoters] = useState([]);
	let [groups, setGroups] = useState([]);
	let [excludedVoters, setExcludedVoters] = useState([]);
	let [selectedTab, setSelectedTab] = useState(votersListTabs.voters);
	let [searchInputValue, setSearchInputValue] = useState("");
	let [dataLoaded, setDataLoaded] = useState(false);

	const getData = () => {
		let voteId = props.voteId;
		API.get(
			"/voteParams",
			{
				voteId,
			},
			(data) => {
				let voterList = data?.vote?.voters;
				let excluded = data?.vote?.excludedVoters;
				setVoters(voterList);
				setExcludedVoters(excluded);

				// Getting distinct groups from the voters' list other than empty string
				setGroups([
					...new Set(
						voterList
							.filter((x) => x.voterGroup !== "" && x.voterGroup)
							.map((x) => x.voterGroup)
					),
				]);

				if (!dataLoaded) setDataLoaded(true);
			}
		);
	};

	const changeExcludedVoter = (voterId) => {
		let voteId = props.voteId;
		let alreadyExcluded = excludedVoters.find(
			(el) => el.voterId == voterId && el.voteId == voteId
		);
		let newExcludedVoter = [...excludedVoters];

		if (alreadyExcluded) {
			newExcludedVoter = newExcludedVoter.filter(
				(el) => el.voterId !== voterId
			);
		} else {
			newExcludedVoter.push(voters.find((el) => el.voterId === voterId));
		}
		setExcludedVoters(newExcludedVoter);

		API.post(
			"/voterInfo",
			{ voteId, voterId, action: "toggleExcludedVoter" },
			(data) => {
				if (data.success) {
					getData();
				}
			}
		);
	};

	const updateGroupExclusion = (group, excluded) => {
		let voteId = props.voteId;
		let agId = props.agId;

		// Update local exclusion for instant changes
		const groupVoters = voters.filter((x) => x.voterGroup === group);
		let newExcludedVoters = excludedVoters;

		if (excluded) {
			const notYetExcludedVoters = groupVoters.filter(
				(groupVoter) =>
					!excludedVoters.some(
						(excludedVoter) => excludedVoter.voterId === groupVoter.voterId
					)
			);

			newExcludedVoters = [...newExcludedVoters, ...notYetExcludedVoters];
		} else {
			newExcludedVoters = newExcludedVoters.filter(
				(x) => x.voterGroup !== group
			);
		}

		setExcludedVoters(newExcludedVoters);

		API.post(
			"/voterInfo",
			{ voteId, agId, group, excluded, action: "excludeVotersGroup" },
			(data) => {
				if (data.success) {
					getData();
				}
			}
		);
	};

	const createTabProps = (index) => {
		return {
			id: `voters-list-tab-${index}`,
			"aria-controls": `voters-list-tab-panel-${index}`,
		};
	};

	const onTabChangeHandler = (event, newValue) => {
		setSelectedTab(newValue);
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<ModalOverlay
			onClick={() => {
				props.closeModal();
			}}
			lgModal
		>
			{dataLoaded ? (
				<Modal onClick={(e) => e.stopPropagation()} lgModal textAlign="left">
					<ModalClose
						onClick={() => {
							props.closeModal();
						}}
					/>
					<Header>Liste des votants</Header>
					{groups.length > 0 && (
						<ProVoteTabs
							value={selectedTab}
							onChange={onTabChangeHandler}
							aria-label="Voters list tabs"
						>
							<ProVoteTab label="Votants" {...createTabProps(0)} />
							<ProVoteTab label="Groupes" {...createTabProps(1)} />
						</ProVoteTabs>
					)}

					<SearchInput
						value={searchInputValue}
						onChange={(event) => setSearchInputValue(event.target.value)}
						placeholder={`Tapez ici pour rechercher ${
							selectedTab === votersListTabs.voters
								? "une personne"
								: "un groupe"
						}...`}
						style={{ marginBottom: 10 }}
					/>

					<VotersListTabPanel value={selectedTab} index={votersListTabs.voters}>
						<Row>
							<RowItemHeader>Prénom</RowItemHeader>
							<RowItemHeader>Nom</RowItemHeader>
							<RowItemHeader style={{ width: "45%" }}>Email</RowItemHeader>
							<span
								style={{
									position: "absolute",
									right: 0,
									fontWeight: "bold",
								}}
							>
								Inclus
							</span>
						</Row>
						<RowsWrapper>
							{voters
								.filter(
									(el) =>
										el.voterFirstname
											.toLowerCase()
											.includes(searchInputValue.toLowerCase()) ||
										el.voterLastname
											.toLowerCase()
											.includes(searchInputValue.toLowerCase()) ||
										el.voterEmail
											.toLowerCase()
											.includes(searchInputValue.toLowerCase())
								)
								.map((el) => {
									let excluded = excludedVoters.find(
										(excludedVoter) => excludedVoter.voterId == el.voterId
									);
									return (
										<Row key={el.voterId} isData>
											<RowItem>{el.voterFirstname}</RowItem>
											<RowItem>{el.voterLastname}</RowItem>
											<RowItem style={{ width: "45%" }}>
												{el.voterEmail}
											</RowItem>
											{!excluded ? (
												<i
													className="fa fa-check-square"
													style={{
														color: "#81c784",
														cursor: "pointer",
														marginTop: "auto",
														marginBottom: "auto",
														fontSize: "20px",
														position: "absolute",
														right: 15,
													}}
													onClick={() => changeExcludedVoter(el.voterId)}
												/>
											) : (
												<i
													className="far fa-square"
													style={{
														color: "black",
														cursor: "pointer",
														marginTop: "auto",
														marginBottom: "auto",
														fontSize: "20px",
														position: "absolute",
														right: 15,
													}}
													onClick={() => changeExcludedVoter(el.voterId)}
												/>
											)}
										</Row>
									);
								})}
						</RowsWrapper>
					</VotersListTabPanel>

					<VotersListTabPanel value={selectedTab} index={votersListTabs.groups}>
						<Row>
							<RowItemHeader>Groupe</RowItemHeader>
							<span
								style={{
									position: "absolute",
									right: 0,
									fontWeight: "bold",
								}}
							>
								Inclus
							</span>
						</Row>
						<RowsWrapper>
							{groups
								.filter((el) =>
									el?.toLowerCase().includes(searchInputValue.toLowerCase())
								)
								.map((el) => {
									let groupVoters = voters.filter((x) => x.voterGroup === el);
									let excluded = true;

									for (const groupVoter of groupVoters) {
										if (
											!excludedVoters.find(
												(excludedVoter) =>
													excludedVoter.voterId == groupVoter.voterId
											)
										) {
											excluded = false;
										}
									}

									return (
										<Row key={el} isData>
											<RowItem>{el}</RowItem>
											{!excluded ? (
												<i
													className="fa fa-check-square"
													style={{
														color: "#81c784",
														cursor: "pointer",
														marginTop: "auto",
														marginBottom: "auto",
														fontSize: "20px",
														position: "absolute",
														right: 15,
													}}
													onClick={() => updateGroupExclusion(el, true)}
												/>
											) : (
												<i
													className="far fa-square"
													style={{
														color: "black",
														cursor: "pointer",
														marginTop: "auto",
														marginBottom: "auto",
														fontSize: "20px",
														position: "absolute",
														right: 15,
													}}
													onClick={() => updateGroupExclusion(el, false)}
												/>
											)}
										</Row>
									);
								})}
						</RowsWrapper>
					</VotersListTabPanel>
				</Modal>
			) : (
				<Loader type="Bars" color="#FFF" height={35} width={35} />
			)}
		</ModalOverlay>
	);
}
