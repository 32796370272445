import React from "react";
import styled from "styled-components";
import API from "../utils/API";
import {
	Modal,
	ModalOverlay,
	ModalImage,
	Semibold,
	Button,
	MediumContainer,
	ModalClose,
	ListTitle
} from "../utils/styled-components-library";
import moment from "moment";
import { Redirect } from "react-router-dom";

const ListContainer = styled.div`
  text-align: left;
  padding: 20px 0px;
`;

const ListRow = styled.div`
  padding: 10px 0px;
  border-bottom: 1px solid #c0c3cb;
  cursor: pointer;

  :hover {
    background-color: #fafafa;
  }
  display: flex;
`;

const ListHeader = styled.div`
  flex-direction: row;
  justify-content: space-between;
  display: flex;
`;

const AgDate = styled.span`
  font-weight: 500;
  margin-right: 8px;
`;

class AGList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			agList: [],
			canCreate: false
		};
	}

	componentDidMount() {
		if (window.location.hash === "#success") {
			this.setState({ showSuccessModal: true });
		}

		API.get("/agList", {}, data => {
			this.setState({
				agList: data.agList,
				canCreate: data.canCreate
			});
		});
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to={this.state.redirect} push />;
		}
		return (
			<>
				<MediumContainer>
					<ListHeader>
						<ListTitle>Vos sessions de vote</ListTitle>
						{this.state.canCreate ? (
							<Button
								width="initial"
								onClick={() => this.setState({ redirect: "/creer-ag" })}
							>
								Créer une session de vote
							</Button>
						) : null}
					</ListHeader>
					<ListContainer>
						{this.state.agList.map(ag => (
							<ListRow
								key={ag.agId}
								onClick={() => this.setState({ redirect: `/ag/${ag.agId}` })}
							>
								<AgDate>{moment(ag.agStartDate).format("DD/MM/YYYY")}</AgDate>{" "}
								{ag.agName}
								{/* <Button style={{backgroundColor: 'red', width: 'auto', marginLeft: 'auto'}}>Clôturer</Button> */}
							</ListRow>
						))}
					</ListContainer>
				</MediumContainer>
				{this.state.showSuccessModal ? (
					<ModalOverlay
						onClick={() => {
							window.location.hash = "";
							this.setState({ showSuccessModal: false });
						}}
					>
						<Modal onClick={e => e.stopPropagation()}>
							<ModalClose
								onClick={() => {
									window.location.hash = "";
									this.setState({ showSuccessModal: false });
								}}
							/>
							<ModalImage src="/img/check.png" alt="" width="100px" />
							<Semibold>Votre mot de passe a bien été modifié !</Semibold>
						</Modal>
					</ModalOverlay>
				) : null}
			</>
		);
	}
}

export default AGList;
