import React from "react";
import {
	ListTitle,
	ListHeader,
	MediumContainer,
	Button,
	Input,
	Label,
	Modal,
	ModalOverlay,
	Error,
	ModalClose,
} from "../utils/styled-components-library";
import AGMenu from "../components/AGMenu";
import API from "../utils/API";
import PresidentAccessTable from "../components/PresidentAccessTable";
import { Formik, Form } from "formik";
import Swal from "sweetalert2";

class AGPresidentAccess extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ag: null,
			showDeletePresidentAccessModal: false,
			showAddPresidentModal: false,
		};
	}

	componentDidMount() {
		this.refreshData();
		this.addRefreshInterval();
	}

	componentWillUnmount() {
		if (this.interval) clearInterval(this.interval);
	}

	clearInterval = () => {
		if (this.interval) clearInterval(this.interval);
	};

	addRefreshInterval = () => {
		this.interval = setInterval(() => this.refreshData(), 3000);
	};

	refreshData() {
		API.get("/agDetails", { agId: this.props.match.params.agId }, (data) => {
			this.setState({
				ag: data,
			});
		});
	}

	onDeletePresidentAccessHandler = (presidentAccess) => {
		this.setState({
			showDeletePresidentAccessModal: true,
			selectedPresidentAccess: presidentAccess,
		});
	};

	deletePresidentAccess = () => {
		API.get(
			"/agPresidents",
			{
				presidentId: this.state.selectedPresidentAccess.presidentId,
				action: "delete",
			},
			(data) => {
				if (data.success) {
					this.refreshData();
					this.setState({
						errorDeletingMsg: "",
						showDeletePresidentAccessModal: false,
						selectedPresidentAccess: null,
					});
				} else {
					this.setState({
						errorDeletingMsg: "Impossible de supprimer le président.",
						showDeletePresidentAccessModal: false,
						selectedPresidentAccess: null,
					});
				}
			}
		);
	};

	render() {
		return (
			<div>
				<MediumContainer>
					<ListHeader>
						<ListTitle>{this.state.ag && this.state.ag.agName}</ListTitle>
					</ListHeader>
					<AGMenu
						activeTab="Accès président"
						ag={this.state.ag}
						{...this.props}
					/>

					{this.state.ag && this.state.ag?.presidents.length === 0 && (
						<h5 style={{ color: "red" }}>
							Aucun président n'a été déclaré pour votre session de vote.
						</h5>
					)}

					{this.state.ag && this.state.ag?.presidents.length > 0 && (
						<>
							<h3 style={{ textAlign: "left" }}>Identifiants président</h3>

							{this.state.ag.presidents.map((president) => {
								return (
									<PresidentAccessTable
										key={president.presidentId}
										presidentAccess={president}
										deletePresidentAccess={this.onDeletePresidentAccessHandler}
									/>
								);
							})}
						</>
					)}
					<Button
						style={{ width: 250, margin: 15 }}
						onClick={() => this.setState({ showAddPresidentModal: true })}
					>
						Ajouter un accès président
					</Button>
				</MediumContainer>

				{/* ===========MODALS =========== */}
				{this.state &&
				this.state.ag?.presidents.length > 0 &&
				this.state.showDeletePresidentAccessModal ? (
					<ModalOverlay
						onClick={() => {
							window.location.hash = "";
							this.setState({
								showDeletePresidentAccessModal: false,
								selectedPresidentAccess: null,
							});
						}}
					>
						<Modal onClick={(e) => e.stopPropagation()}>
							<ModalClose
								onClick={() => {
									window.location.hash = "";
									this.setState({
										showDeletePresidentAccessModal: false,
										selectedPresidentAccess: null,
									});
								}}
							/>
							<h4>
								Êtes-vous sûr(e) de vouloir supprimer l'accès président lié à{" "}
								{this.state.selectedPresidentAccess.presidentEmail}?
							</h4>
							<div style={{ display: "flex", flexDirection: "row" }}>
								<Button
									style={{ backgroundColor: "red", marginRight: "5px" }}
									onClick={() => this.deletePresidentAccess()}
								>
									Supprimer
								</Button>
								<Button
									onClick={() => {
										window.location.hash = "";
										this.setState({
											showDeletePresidentAccessModal: false,
											selectedPresidentAccess: null,
										});
									}}
								>
									Annuler
								</Button>
							</div>
						</Modal>
					</ModalOverlay>
				) : null}

				{this.state && this.state.showAddPresidentModal ? (
					<ModalOverlay
						onClick={() => {
							window.location.hash = "";
							this.setState({
								showAddPresidentModal: false,
							});
						}}
					>
						<Modal onClick={(e) => e.stopPropagation()}>
							<ModalClose
								onClick={() => {
									window.location.hash = "";
									this.setState({
										showAddPresidentModal: false,
									});
								}}
							/>
							<Formik
								initialValues={{ presidentEmail: "" }}
								validate={(values) => {
									const errors = {};

									if (!values.presidentEmail) {
										errors.presidentEmail = "Requis";
									} else if (
										!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
											values.presidentEmail
										)
									) {
										errors.presidentEmail = "Adresse email invalide";
									}

									return errors;
								}}
								onSubmit={(values, { setSubmitting, setErrors }) => {
									API.post(
										"/agPresidents",
										{
											...values,
											agId: this.props.match.params.agId,
											action: "add",
										},
										(data) => {
											setSubmitting(false);
											if (data.success) {
												this.setState({
													showAddPresidentModal: false,
												});
												Swal.fire({
													title: "Nouvel accès président ajouté !",
													icon: "success",
													confirmButtonText: "Ok",
												});
												this.refreshData();
											} else {
												setErrors({ presidentEmail: data.errorMessage });
											}
										}
									);
								}}
								validateOnMount
							>
								{({ isSubmitting, isValid }) => (
									<Form autoComplete="off">
										<Label>Email du président</Label>
										<Input name="presidentEmail" />
										<Error name="presidentEmail" component="div" />
										<div style={{ display: "flex", flexDirection: "row" }}>
											<Button
												type="button"
												style={{ marginRight: "5px" }}
												white
												onClick={() => {
													window.location.hash = "";
													this.setState({
														showAddPresidentModal: false,
													});
												}}
											>
												Annuler
											</Button>
											<Button type="submit" disabled={!isValid || isSubmitting}>
												Ajouter
											</Button>
										</div>
									</Form>
								)}
							</Formik>
						</Modal>
					</ModalOverlay>
				) : null}
			</div>
		);
	}
}

export default AGPresidentAccess;
