import React from "react";
import AGList from "../screens/AGList";
import AGCreateOrEdit from "../screens/AGCreateOrEdit";
import AGDetails from "../screens/AGDetails";
import AGDocuments from "../screens/AGDocuments";
import AGVoterList from "../screens/AGVoterList";
import VoteCreateOrEdit from "../screens/VoteCreateOrEdit";
import UpdatePassword from "../screens/UpdatePassword";
import PageNotFound from "../screens/PageNotFound";
import Vote from "../screens/Vote";
import ClientAttendance from "../screens/ClientAttendance";
import VoteResults from "../screens/VoteResults";
import { Route, Switch } from "react-router-dom";
import TitleComponent from "../components/TitleComponent";
import AGPresidentAccess from "../screens/AGPresidentAccess";

class ClientNavigator extends React.Component {
	render() {
		return (
			<>
				<TitleComponent title="ProVote - Administrateur" />
				<Switch>
					<Route exact path="/" component={AGList} />
					<Route exact path="/ag/:agId" component={AGDetails} />
					<Route exact path="/ag/:agId/documents" component={AGDocuments} />
					<Route exact path="/ag/:agId/inscrits" component={AGVoterList} />
					<Route
						exact
						path="/ag/:agId/modifier"
						render={(props) => <AGCreateOrEdit edit={true} {...props} />}
					/>
					<Route
						exact
						path="/ag/:agId/parametres/:voteId"
						render={(props) => <VoteCreateOrEdit edit={true} {...props} />}
					/>
					<Route exact path="/creer-ag" component={AGCreateOrEdit} />
					<Route
						exact
						path="/ag/:agId/creer-vote"
						render={(props) => <VoteCreateOrEdit {...props} />}
					/>
					<Route
						exact
						path="/ag/:agId/emargement"
						component={ClientAttendance}
					/>
					<Route
						exact
						path="/ag/:agId/resultats/:voteId?"
						component={VoteResults}
					/>
					<Route
						exact
						path="/ag/:agId/accesPresident"
						component={AGPresidentAccess}
					/>
					<Route
						exact
						path="/modifier-mot-de-passe"
						render={() => <UpdatePassword userType="client" />}
					/>
					<Route
						exact
						path="/vote/:voteId"
						component={(props) => <Vote user={this.props.user} {...props} />}
					/>
					<Route component={PageNotFound} />
				</Switch>
			</>
		);
	}
}

export default ClientNavigator;
