import React from "react";
import { ChartContainer } from "../../utils/styled-components-library";
import styled from "styled-components";
import Chart from "./Chart";
import TableVoteResults from "./TableVoteResults";
import  { colors } from "./chartColors";

const SmallTitle = styled.div`
  font-size: 24px;
  margin: 0 auto;
  margin-bottom: 15px;
  text-align: center;
`;

export default function PlurinominalVoteResults({ vote, printing, index }) {

  const sortedVoteResults = vote.voteResults.sort((a, b) => {
    return (b.count || 0) - (a.count || 0);
  });

  const castedVoteCount = vote.useVotePowers 
    ? vote.castedVotesWithPowers : vote.castedVotes;
  const nonCastedVoteCount = vote.useVotePowers
    ? vote.expectedVotesWithPowers - vote.castedVotesWithPowers
    : vote.expectedVotes - vote.castedVotes

  return (
    <ChartContainer
      key={vote.voteId}
      index={index}
      printing={printing}
    >
      <SmallTitle>{vote.voteName}</SmallTitle>
      <p>
        Nombre de suffrage(s) exprimé(s) : {castedVoteCount} <br />
        Nombre de non voté(s) : {nonCastedVoteCount}
      </p>

      { vote.resultGraphType === "table" ? 
        (
          <TableVoteResults
            resultDisplayType={vote.resultDisplayType}
            voteResults={sortedVoteResults}
            voteType={vote.voteType}
          />
        ) : (
          <Chart
            chartType={vote.resultGraphType}
            displayType={vote.resultDisplayType}
            data={sortedVoteResults.map((x) => x.count)}
            labels={sortedVoteResults.map((x) =>
              x.optionType === "voteAbstain" &&
              vote.voteType !== "resolutionVote"
                ? "Vote(s) blanc(s)"
                : x.optionName
            )}
            colors={sortedVoteResults.map((x) => colors[x.optionType])}
          />
        )
      }
    </ChartContainer>
  )
}