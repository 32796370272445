import React from "react";
import AGCreateOrEdit from "../screens/AGCreateOrEdit";
import AGDetails from "../screens/AGDetails";
import AGDocuments from "../screens/AGDocuments";
import AGVoterList from "../screens/AGVoterList";
import PageNotFound from "../screens/PageNotFound";
import Vote from "../screens/Vote";
import ClientAttendance from "../screens/ClientAttendance";
import VoteResults from "../screens/VoteResults";
import { Route, Switch } from "react-router-dom";
import TitleComponent from "../components/TitleComponent";
import VoteCreateOrEdit from "../screens/VoteCreateOrEdit";

class PresidentNavigator extends React.Component {
	render() {
		return (
			<>
				<TitleComponent title="ProVote - Président" />
				<Switch>
					<Route
						exact
						path="/"
						component={(props) => (
							<AGDetails agId={this.props.user.agId} {...props} />
						)}
					/>
					<Route
						exact
						path="/documents"
						component={(props) => (
							<AGDocuments agId={this.props.user.agId} {...props} />
						)}
					/>
					<Route
						exact
						path="/inscrits"
						component={(props) => (
							<AGVoterList agId={this.props.user.agId} {...props} />
						)}
					/>
					<Route
						exact
						path="/creer-ag"
						component={(props) => (
							<AGCreateOrEdit agId={this.props.user.agId} {...props} />
						)}
					/>
					<Route
						exact
						path="/emargement-president"
						component={(props) => (
							<ClientAttendance agId={this.props.user.agId} {...props} />
						)}
					/>
					<Route
						exact
						path="/resultats/:voteId?"
						component={(props) => (
							<VoteResults agId={this.props.user.agId} {...props} />
						)}
					/>
					<Route
						exact
						path="/parametres/:voteId"
						render={(props) => (
							<VoteCreateOrEdit
								agId={this.props.user.agId}
								edit={true}
								{...props}
							/>
						)}
					/>
					<Route
						exact
						path="/vote/:voteId"
						component={(props) => <Vote user={this.props.user} {...props} />}
					/>
					<Route component={PageNotFound} />
				</Switch>
			</>
		);
	}
}

export default PresidentNavigator;
