export function sortVotesByPosition(votes) {
	return [...votes].sort((a, b) => {
		if (b.votePosition == 0 || a.votePosition == 0) {
			return a.voteId - b.voteId;
		} else {
			return a.votePosition - b.votePosition;
		}
	});
}

export function voterIsParentVoter(voters, currVoter) {
	return voters.some(
		(tmpVoter) => tmpVoter.parentVoterId === currVoter.voterId
	);
}
