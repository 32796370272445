import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

function VoteIcon({ iconName, style, size }) {
	return (
		<>
			{iconName === "check" ? (
				<CheckIcon style={style} fontSize={size} />
			) : iconName === "close" ? (
				<CloseIcon style={style} fontSize={size} />
			) : iconName === "remove" ? (
				<RemoveIcon style={style} fontSize={size} />
			) : iconName === "add" ? (
				<AddIcon style={style} fontSize={size} />
			) : null}
		</>
	);
}

export default VoteIcon;
