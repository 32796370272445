import React from "react";
import {
	MediumContainer,
	Title,
	StyledLink
} from "../utils/styled-components-library";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
	text-align: left;
`;

class PageNotFound extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		if (this.state.redirect) {
			return <Redirect to={this.state.redirect} push />;
		}
		return (
			<MediumContainer>
				<Title>Erreur 404 - Page non disponible</Title>
				<Container>
					<p>La page demandée n'existe pas, ou elle n'est plus disponible.</p>
					<StyledLink to="/">Retour à la page d'accueil</StyledLink>
				</Container>
			</MediumContainer>
		);
	}
}

export default PageNotFound;
