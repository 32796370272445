import React, { useState, useEffect } from "react";
import styled from "styled-components";
import API from "../../utils/API";
import { sortVotesByPosition } from "../../utils/votes";
import VoteResultsItem from "./VoteResultsItem";

const SmallTitle = styled.div`
  font-size: 24px;
  margin: 0 auto;
  margin-bottom: 15px;
  text-align: center;
`;

export default function VoteResultsList({ agId, voteId, printing = false }) {
  const searchParams = new URLSearchParams(window.location.search);

  const [votes, setVotes] = useState([]);

  const getVotesData = () => {
    API.get(
      "/voteResults",
      {
        agId: agId || searchParams.get("agId"),
        voteId: voteId || searchParams.get("voteId"),
      },
      (votes) => {
        setVotes(votes);
      }
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getVotesData(), []);

  const sortedVotes = sortVotesByPosition(votes);

  if (!votes || votes.length === 0) return (
    <SmallTitle style={{ textAlign: "center" }}>
      La ressource demandée n'existe pas.
    </SmallTitle>
  );

  return <>
    { sortedVotes.map((vote, index) => 
      <VoteResultsItem vote={vote} printing={printing} index={index} key={vote.voteId} />
    )}
  </>
  
}
