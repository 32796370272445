import React from "react";
import styled from "styled-components";
import * as config from "../config";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import * as Auth from "../utils/Auth";
import API from "../utils/API";
import {
  SmallClientLogo,
  Button,
  Modal,
  ModalOverlay,
  ModalClose,
  Label,
  CenteredLabel,
  Input,
  Error,
} from "../utils/styled-components-library";
import { Formik, Form } from "formik";
import BrowseDocuments from "../screens/BrowseDocuments";

const HeaderDiv = styled.div`
  position: fixed;
  width: 100%;
  background-color: white;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
  height: 64px;
`;

const MenuLink = styled.div`
  cursor: pointer;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #eee;
  background-color: white;
  :hover {
    background-color: #fafafa;
  }
`;

const MenuIcon = styled.div`
  margin-right: 8px;
`;

const Menu = styled.div`
  color: #333;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 220px;
  @media (max-width: 768px) {
    width: 110px;
  }
`;

const MenuHeader = styled.div`
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #eee;
  cursor: pointer;
`;

const ClientLogoContainer = styled.div`
  position: absolute;
  right: 250px;
  top: 2px;
  @media (max-width: 768px) {
    right: 110px;
  }
`;

const MobileDisplay = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
  }
`;

const DesktopDisplay = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

const MenuContent = styled.div`
  display: none;
  width: 220px;
  ${Menu}:hover > & {
    display: block;
  }
  @media (max-width: 768px) {
    margin-left: -110px;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: initial;
`;

const MenuHeaderText = styled.div`
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #1d529c;
  margin-bottom: 26px;
`;

const MenuItem = styled.div`
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #06101f;
  margin-bottom: 13px;
`;

const MenuModal = styled(Modal)`
  width: 300px;
  position: absolute;
  right: 5px;
  top: -30px;
  @media (max-width: 768px) {
    left: 5px;
    width: auto;
  }
`;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ag: null,
      documentsCount: 0,
      showDelegateModal: false,
      showDocumentsModal: false,
      possibleDelegations: [],
    };

    if (props.isLogged) {
      this.fetchAg();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isLogged) {
      this.fetchAg();
    }
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }

  fetchAg() {
    API.get(
      "/voteParams",
      {
        voteId: this.props.match.params.voteId,
      },
      (data) => {
        this.setState({
          ag: data.ag,
          possibleDelegations: data.ag.otherVoters.filter((voter) => {
            if (!voter.receivedDelegations) return true;
            else if (
              data.ag.maximumDelegatedPower == 0 ||
              voter.receivedDelegations + 1 <= data.ag.maximumDelegatedPower
            )
              return true;
            else return false;
          }).sort((a, b) => a.voterLastname.localeCompare(b.voterLastname) || a.voterFirstname.localeCompare(b.voterFirstname)),
        });

        this.interval = setInterval(() => {
          this.fetchDocumentsCount();
        }, 10000);
      }
    );
  }

  fetchDocumentsCount() {
    API.get(
      "/agDocuments",
      { agId: this.state.ag?.agId, action: "get" },
      (data) => {
        if (data.success) {
          this.setState({ documentsCount: data.documents.length });
        }
      }
    );
  }

  logout() {
    Auth.removeToken();
    this.props.history.push("/");
    this.props.onLogout();
  }

  render() {
    return (
      <>
        <HeaderDiv className="hideOnPrint">
          <DesktopDisplay>
            <StyledLink to="/">
              <img alt="ProVote" src="/logo.jpg" height="60" />
              <span style={{ marginLeft: 5 }}>ProVote</span>
            </StyledLink>

            {this.props.isLogged && this.props.user ? (
              <ClientLogoContainer>
                <SmallClientLogo
                  src={config.s3BaseUrl + this.props.user.clientLogoPath}
                  alt="Client logo"
                />
              </ClientLogoContainer>
            ) : null}
          </DesktopDisplay>
          <MobileDisplay>
            <StyledLink to="/">
              <img alt="ProVote" src="/logo.jpg" height="60" />
            </StyledLink>
            {this.props.isLogged && this.props.user ? (
              <ClientLogoContainer isMobile>
                <SmallClientLogo
                  src={config.s3BaseUrl + this.props.user.clientLogoPath}
                  alt="Client logo"
                />
              </ClientLogoContainer>
            ) : null}
          </MobileDisplay>
          {this.props.isLogged && this.props.user ? (
            <Menu>
              <MenuHeader>
                <DesktopDisplay>
                  <MenuIcon>
                    <i className="far fa-bars" />
                  </MenuIcon>

                  {this.props.user.userType === "president"
                    ? "Président"
                    : this.props.user.name}
                </DesktopDisplay>
                <MobileDisplay>
                  <MenuIcon>
                    <i className="far fa-bars" />
                  </MenuIcon>
                </MobileDisplay>
              </MenuHeader>
              {this.props.user.userType === "voter" && (
                <>
                  <MenuContent>
                    <Link
                      to="/mes-votes"
                      style={{ textDecoration: "none", color: "#333" }}
                    >
                      <MenuLink>Mes votes</MenuLink>
                    </Link>
                  </MenuContent>
                  <MenuContent>
                    <a
                      onClick={() =>
                        this.setState({ showDocumentsModal: true })
                      }
                      style={{ textDecoration: "none", color: "#333" }}
                    >
                      <MenuLink>
                        Documents ({this.state.documentsCount})
                      </MenuLink>
                    </a>
                  </MenuContent>
                  {this.state.ag?.canVotersDelegateVotes ? (
                    <MenuContent>
                      <a
                        onClick={() =>
                          this.setState({ showDelegateModal: true })
                        }
                        style={{ textDecoration: "none", color: "#333" }}
                      >
                        <MenuLink>Déléguer mon pouvoir</MenuLink>
                      </a>
                    </MenuContent>
                  ) : null}
                  <MenuContent>
                    <a
                      href={`${config.apiUrl}/ordreDuJourPdf?agId=${this.state.ag?.agId}`}
                      style={{ textDecoration: "none", color: "#333" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MenuLink>Télécharger l'ordre du jour</MenuLink>
                    </a>
                  </MenuContent>
                </>
              )}
              {this.props.user.userType !== "president" && (
                <MenuContent>
                  <Link
                    to="/modifier-mot-de-passe"
                    style={{ textDecoration: "none", color: "#333" }}
                  >
                    <MenuLink>Modifier mon mot de passe</MenuLink>
                  </Link>
                </MenuContent>
              )}

              <MenuContent>
                <MenuLink onClick={() => this.logout()}>
                  <MenuIcon>
                    <i className="far fa-sign-out" />
                  </MenuIcon>
                  <>Se déconnecter</>
                </MenuLink>
              </MenuContent>
            </Menu>
          ) : null}
        </HeaderDiv>

        {/* Popup pour déléguer son pouvoir */}
        {this.state.showDelegateModal && (
          <ModalOverlay
            onClick={() => this.setState({ showDelegateModal: false })}
          >
            <Modal onClick={(e) => e.stopPropagation()}>
              <ModalClose
                onClick={() => this.setState({ showDelegateModal: false })}
              />

              {this.props.user.voterPowerCount !== 0 ? (
                <Formik
                  initialValues={{
                    delegateVoterId: null,
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.delegateVoterId) {
                      errors.delegateVoterId =
                        "Veuillez sélectionner une personne à qui déléguer votre pouvoir.";
                    }
                    return errors;
                  }}
                  onSubmit={(
                    values,
                    { setSubmitting, setErrors, resetForm }
                  ) => {
                    API.post("/delegatePower", values, (data) => {
                      setSubmitting(false);
                      if (data.success) {
                        this.setState({ showDelegateModal: false });
                      } else {
                        setErrors({ delegateVoterId: data.message });
                      }
                    });
                  }}
                  validateOnMount
                >
                  {({ isSubmitting, isValid, values }) => (
                    <Form>
                      {this.state.possibleDelegations.length > 0 ? (
                        <>
                          <Label>Déléguer mon pouvoir à</Label>
                          <Input component="select" name="delegateVoterId">
                            <option value="">-</option>
                            {this.state.possibleDelegations.map((voter) => (
                              <option key={voter.voterId} value={voter.voterId}>
                                {voter.voterFirstname} {voter.voterLastname}
                              </option>
                            ))}
                          </Input>
                          <div
                            style={{
                              marginBottom: "10px",
                              color: "red",
                              fontWeight: "500",
                            }}
                          >
                            Attention ! Cette action est irréversible et vous ne
                            pourrez plus accéder au vote une fois votre pouvoir
                            délégué !
                          </div>
                          <Error
                            name="delegateVoterId"
                            component="div"
                            style={{ textAlign: "center" }}
                          />
                        </>
                      ) : (
                        <CenteredLabel style={{ marginBottom: 10 }}>
                          Aucun voteur n'est éligible à recevoir vos pouvoirs
                        </CenteredLabel>
                      )}

                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {this.state.possibleDelegations.length > 0 ? (
                          <>
                            <Button
                              onClick={this.submit}
                              disabled={!isValid || isSubmitting}
                            >
                              Déléguer mon pouvoir
                            </Button>
                            <Button
                              onClick={() =>
                                this.setState({ showDelegateModal: false })
                              }
                              style={{ marginLeft: "20px" }}
                            >
                              Annuler
                            </Button>
                          </>
                        ) : (
                          <Button
                            onClick={() =>
                              this.setState({ showDelegateModal: false })
                            }
                            style={{ marginTop: 10 }}
                          >
                            Fermer
                          </Button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              ) : (
                <div
                  style={{
                    marginBottom: "10px",
                    color: "red",
                    fontWeight: "500",
                  }}
                >
                  Il vous est impossible déléguer votre pouvoir car vous avez un
                  pouvoir de 0.
                </div>
              )}
            </Modal>
          </ModalOverlay>
        )}

        {/* Popup d'affichage des documents */}
        {this.state.showDocumentsModal && (
          <ModalOverlay
            onClick={() => this.setState({ showDocumentsModal: false })}
          >
            <Modal
              onClick={(e) => e.stopPropagation()}
              style={{ maxWidth: 900 }}
            >
              <ModalClose
                onClick={() => this.setState({ showDocumentsModal: false })}
              />
              <BrowseDocuments />
            </Modal>
          </ModalOverlay>
        )}
      </>
    );
  }
}

export default withRouter(Header);
