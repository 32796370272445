import React from "react";
import { ChartContainer, WinnerText } from "../../utils/styled-components-library";
import styled from "styled-components";
import Chart from "./Chart";
import TableVoteResults from "./TableVoteResults";
import  { colors } from "./chartColors";

const SmallTitle = styled.div`
  font-size: 24px;
  margin: 0 auto;
  margin-bottom: 15px;
  text-align: center;
`;

export default function ResolutionVoteResults({ vote, printing, index }) {
  const castedVoteCount = vote.useVotePowers 
    ? vote.castedVotesWithPowers : vote.castedVotes;
  const nonCastedVoteCount = vote.useVotePowers
    ? vote.expectedVotesWithPowers - vote.castedVotesWithPowers
    : vote.expectedVotes - vote.castedVotes

  return (
    <ChartContainer
      key={vote.voteId}
      printing={printing}
      index={index}
    >
      <SmallTitle>{vote.voteName}</SmallTitle>
      <p>
        Nombre de suffrage(s) exprimé(s) : {castedVoteCount} <br />
        Nombre de non voté(s) : {nonCastedVoteCount}
      </p>

      {vote.showRequiredMajority ? (
        <p>
          Majorité (
          {vote.majoriteCalculResultat === "simple"
            ? "simple"
            : "renforcée"}
          ) requise : {vote.requiredMajority} suffrages
        </p>
      ): null}

      {
        vote.voteResults.reduce(
          (acc, cur) => acc + (cur.count || 0),
          0
        ) > 0 && vote.showCalculResultat === 1 ? (
          <div style={{ fontWeight: "500", marginBottom: 20 }}>
            {vote.resolutionWord} :{" "}
            {vote.isResolutionAccepted ? (
              <WinnerText>Acceptation</WinnerText>
            ) : (
              <span style={{ color: "red" }}>Rejet</span>
            )}
            .
          </div>
        ) : null
      }


      { vote.resultGraphType === "table" ? 
        (
          <TableVoteResults
            resultDisplayType={vote.resultDisplayType}
            voteResults={vote.voteResults}
            voteType={vote.voteType}
          />
        ) : (
          <Chart
            chartType={vote.resultGraphType}
            displayType={vote.resultDisplayType}
            data={vote.voteResults.map((x) => x.count)}
            labels={vote.voteResults.map((x) => x.optionName)}
            colors={vote.voteResults.map((x) => colors[x.optionType])}
          />
        )
      }
    </ChartContainer>
  )
}