import React from "react";
import styled from "styled-components";
import {
  Button,
  Input,
  Label,
  MediumContainer,
  Title,
  Error,
  InputGroup,
  InputGroupItem,
  HorizontalSeparator,
} from "../utils/styled-components-library";
import { Formik, Form, FieldArray } from "formik";
import API from "../utils/API";
import { Redirect } from "react-router-dom";
import AGMenu from "../components/AGMenu";
import FileInput from "../components/FileInput";
import moment from "moment";

const SquareButton = styled.button`
  margin-bottom: 12px;
  background-color: white;
  border: solid 1px #c0c3cb;
  height: 41px;
  width: 41px;
  cursor: pointer;

  :hover {
    background-color: #eee;
  }
`;

class VoteCreateOrEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: null,
      ag: null,
      canEdit: false,
      isFileUploading: false,
      attendances: null,
    };
  }

  componentDidMount() {
    this.getAgData();
    if (this.props.edit) {
      this.loadVoteData();
    } else {
      this.getVoteDefaultParams();
    }
  }

  loadVoteData() {
    API.get(
      "/voteParams",
      {
        voteId: this.props.match.params.voteId,
      },
      (data) => {
        let initialValues = data.vote;
        if (initialValues.voteType === "resolutionVote") {
          let extraOption = initialValues.options.find((x) => x.optionType === "voteExtraOption");
          initialValues.voteExtraOptionLabel = extraOption?.optionName || "";

          initialValues.voteForLabel =
            initialValues.options.find((x) => x.optionType === "voteFor")?.optionName || "Pour";
          initialValues.voteAgainstLabel =
            initialValues.options.find((x) => x.optionType === "voteAgainst")?.optionName ||
            "Contre";
          initialValues.voteAbstainLabel = initialValues.options.find(
            (x) => x.optionType === "voteAbstain"
          )
            ? initialValues.options.find((x) => x.optionType === "voteAbstain")?.optionName ||
              "Abstention"
            : null;
        } else {
          initialValues.candidates = initialValues.options.reduce(function (result, x) {
            const candidate = JSON.parse(x.optionMetadata);
            if (candidate && !candidate.isOffList) {
              result.push(candidate);
            }
            return result;
          }, []);

          initialValues.offListCandidates = initialValues.options.reduce(function (result, x) {
              const candidate = JSON.parse(x.optionMetadata);
              if (candidate && candidate.isOffList) {
                result.push(candidate);
              }
              return result;
          }, []);
        }
        this.setState(
          {
            initialValues: data.vote,
            canEdit: data.vote.voteStartDatetime ? false : true,
          },
          () => this.getAttendances()
        );
      }
    );
  }

  voteCandidatesIsValid = (candidates) => {
    if (!candidates || candidates.length === 0) return false;

    for (const candidate of candidates) {
      if (candidate.firstname || candidate.lastname || candidate.info) {
        return true;
      }
    }

    return false;
  };

  getVoteDefaultParams = () => {
    API.get(
      "/voteDefaultParams",
      { agId: this.props.agId || this.props.match.params.agId },
      (data) => {
        this.setState(
          {
            initialValues: { ...data },
            canEdit: true,
          },
          () => this.getAttendances()
        );
      }
    );
  };

  getAgData() {
    API.get("/agDetails", { agId: this.props.agId || this.props.match.params.agId }, (data) => {
        if (data.hasSignatureList) {
          this.setState({
            ag: data,
          });
        }
    });
  }

  getAttendances = () => {
    API.get(
      "/clientAttendancesDetails",
      { agId: this.props.agId || this.props.match.params.agId },
      (data) => {
        // regarder si on plusieurs émargements
        let attendances = data.attendances;
        if (attendances.length > 0) {
          let initialValues = {
            ...this.state.initialValues,
            attendanceId: attendances[0].attendanceId,
          };
          if (this.state.initialValues.linkedAttendanceId) {
            initialValues = {
              ...initialValues,
              attendanceId: this.state.initialValues.linkedAttendanceId,
            };
          }
          this.setState({ attendances, initialValues });
        } else {
          this.setState({ attendances: [] });
        }
      }
    );
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    }
    return (
      <MediumContainer>
        <div style={{ marginBottom: "10px" }}>
          <AGMenu activeTab="Votes" ag={this.state.ag} {...this.props} />
        </div>
        {this.state.initialValues && this.state.attendances ? (
          <Title>
            {!this.state.canEdit
              ? "Paramètres"
              : this.props.edit
              ? "Modifier les paramètres du vote"
              : "Ajouter un vote"}
          </Title>
        ) : null}
        {this.state.initialValues && this.state.attendances ? (
          <Formik
            initialValues={this.state.initialValues}
            validate={(values) => {
              const errors = {};
              if (!values.voteName) {
                errors.voteName = "Requis";
              }
              if (!values.quorum) {
                values.quorum = 0;
              }
              if (values.voteType === "parityPlurinominalElection") {
                if (!values.plurinominalElectionMaxMenVotes || values.plurinominalElectionMaxMenVotes <= 0) {
                  errors.plurinominalElectionMaxMenVotes = "Requis";
                }
                if (!values.plurinominalElectionMaxWomenVotes || values.plurinominalElectionMaxWomenVotes <= 0) {
                  errors.plurinominalElectionMaxWomenVotes = "Requis";
                }
              }
              if (
                values.voteType !== "resolutionVote" &&
                !this.voteCandidatesIsValid(values.candidates)
              ) {
                errors.candidates = "Requis";
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              API.post("/createOrEditVote", values, (data) => {
                setSubmitting(false);
                if (!data || data.success) {
                  this.setState({
                    redirect: `/ag/${this.props.match.params.agId}`,
                  });
                } else {
                  setErrors({
                    'off-list-candidates': data.error,
                  });
                }
              });
            }}
            validateOnMount
          >
            {({ isSubmitting, isValid, values }) => (
              <Form>
                <InputGroup>
                  <InputGroupItem>
                    <Label>Type de vote</Label>
                    <Input
                      component="select"
                      name="voteType"
                      disabled={!this.state.canEdit || (this.state.ag && this.state.ag.presidentId)}
                    >
                      <option value="resolutionVote">
                        Vote {this.state.ag && this.state.ag.resolutionWord}
                      </option>
                      <option value="oneRoundElection">
                        Élection uninominale majoritaire à 1 tour
                      </option>
                      <option value="twoRoundsElection">
                        Élection uninominale majoritaire à 2 tours
                      </option>
                      <option value="plurinominalElection">
                        Élection plurinominale
                      </option>
                      <option value="parityPlurinominalElection">
                        Élection plurinominale paritaire
                      </option>
                    </Input>
                  </InputGroupItem>
                  {values.voteType === "plurinominalElection" && (
                    <InputGroupItem>
                      <Label>Nombre de votes possibles</Label>
                      <Input
                        name="plurinominalElectionMaxVotes"
                        type="number"
                        disabled={!this.state.canEdit || this.state.ag.presidentId}
                      />
                      <Error name="plurinominalElectionMaxVotes" component="div" />
                    </InputGroupItem>
                  )}
                </InputGroup>
                {values.voteType === "parityPlurinominalElection" && (
                  <>
                  <InputGroup style={{ alignItems: "flex-start" }}>
                    <InputGroupItem>
                      <Label>Nombre de votes candidat(s) homme(s) possibles*</Label>
                      <Input
                        name="plurinominalElectionMaxMenVotes"
                        type="number"
                        disabled={!this.state.canEdit || this.state.ag.presidentId}
                      />
                      <Error name="plurinominalElectionMaxMenVotes" component="div" />
                    </InputGroupItem>
                    <InputGroupItem>
                      <Label>Nombre de votes candidate(s) femme(s) possibles*</Label>
                      <Input
                        name="plurinominalElectionMaxWomenVotes"
                        type="number"
                        disabled={!this.state.canEdit || this.state.ag.presidentId}
                      />
                      <Error name="plurinominalElectionMaxWomenVotes" component="div" />
                    </InputGroupItem>
                  </InputGroup>
                  <>
                    <Label>Nombre de votes totaux possibles</Label>
                    <Input
                      type="number"
                      name="plurinominalElectionMaxVotes"
                      value={
                        values.plurinominalElectionMaxMenVotes +
                        values.plurinominalElectionMaxWomenVotes
                      }
                      disabled
                    />
                  </>
                </>
                )}
                {this.state.ag &&
                this.state.ag.hasSignatureList &&
                this.state.ag.multipleAttendance &&
                this.state.attendances &&
                this.state.attendances.length > 0 ? (
                  <>
                    <Label>Émargement</Label>
                    <Input
                      component="select"
                      name="attendanceId"
                      disabled={!this.state.canEdit || this.state.ag.presidentId}
                    >
                      {this.state.attendances &&
                        this.state.attendances.length > 0 &&
                        this.state.attendances.map((attendance) => (
                          <option key={attendance.attendanceId} value={attendance.attendanceId}>
                            {attendance.label
                              ? attendance.label + " - "
                              : "" +
                                attendance.choice +
                                " (" +
                                moment(attendance.date).format("DD/MM/YYYY") +
                                ")"}
                          </option>
                        ))}
                    </Input>
                  </>
                ) : null}

                <Label>
                  {values.voteType === "resolutionVote"
                    ? "Libellé de la " +
                      upperCaseFirstLetter(this.state.ag && this.state.ag.resolutionWord)
                    : "Libellé de l'élection"}
                  *
                </Label>
                <Input
                  name="voteName"
                  disabled={!this.state.canEdit || this.state.ag.presidentId}
                />
                <Error name="voteName" component="div" />

                <Label>Description</Label>
                <Input
                  name="voteDescription"
                  disabled={!this.state.canEdit || this.state.ag.presidentId}
                  component="textarea"
                />
                {/* {values.voteType === "twoRoundsElection" ? (
									<>
										<Label>Majorité</Label>
										<Input
											component="select"
											name="majorityType"
											disabled={
												!this.state.canEdit || this.state.ag.presidentId
											}
										>
											<option value="simple">Simple</option>
											<option value="absolute">Absolue</option>
										</Input>
									</>
								) : null} */}
                <Label>Vote anonyme</Label>
                <Input
                  component="select"
                  name="isNominative"
                  disabled={!this.state.canEdit || this.state.ag.presidentId}
                >
                  <option value="0">Oui</option>
                  <option value="1">Non</option>
                </Input>
                <Label>Prendre en compte les poids des votants</Label>
                <Input
                  component="select"
                  name="useVotePowers"
                  disabled={!this.state.canEdit || this.state.ag.presidentId}
                >
                  <option value="1">Oui</option>
                  <option value="0">Non</option>
                </Input>
                <Label>Prendre en compte les délégations de pouvoirs</Label>
                <Input
                  component="select"
                  name="useVoteDelegations"
                  disabled={!this.state.canEdit || this.state.ag.presidentId}
                >
                  <option value="1">Oui</option>
                  <option value="0">Non</option>
                </Input>
                <InputGroup>
                  <InputGroupItem>
                    <Label>Quorum*</Label>
                    <Input
                      name="quorum"
                      type="number"
                      disabled={!this.state.canEdit || this.state.ag.presidentId}
                    />
                  </InputGroupItem>
                  <InputGroupItem>
                    <Input
                      component="select"
                      name="quorumUnit"
                      disabled={!this.state.canEdit || this.state.ag.presidentId}
                    >
                      <option value="% des votants">% des votants</option>
                      <option value="votants">votants</option>
                    </Input>
                  </InputGroupItem>
                  <InputGroupItem>
                    <Label>Afficher le quorum</Label>
                    <Input
                      component="select"
                      name="showQuorum"
                      disabled={!this.state.canEdit || this.state.ag.presidentId}
                    >
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                    </Input>
                  </InputGroupItem>
                </InputGroup>

                {values.voteType === "resolutionVote" ? (
                  <InputGroup>
                    <InputGroupItem>
                      <Label>Majorité requise</Label>
                      <Input
                        name="requiredMajority"
                        type="number"
                        disabled={!this.state.canEdit || this.state.ag.presidentId}
                      />
                    </InputGroupItem>
                    <InputGroupItem>
                      <Label>Afficher la majorité requise</Label>
                      <Input
                        component="select"
                        name="showRequiredMajority"
                        disabled={!this.state.canEdit || this.state.ag.presidentId}
                      >
                        <option value="1">Oui</option>
                        <option value="0">Non</option>
                      </Input>
                    </InputGroupItem>
                  </InputGroup>
                ) : null}

                <Label>
                  Possibilité de{" "}
                  {values.voteType === "resolutionVote" ? "s'abstenir" : "voter blanc"}
                </Label>
                <Input
                  component="select"
                  name="canAbstain"
                  disabled={!this.state.canEdit || this.state.ag.presidentId}
                >
                  <option value="1">Oui</option>
                  <option value="0">Non</option>
                </Input>
                {values.voteType === "resolutionVote" ? (
                  <>
                    <InputGroup>
                      <InputGroupItem>
                        <Label>Nom de l'option Pour</Label>
                        <Input
                          name="voteForLabel"
                          disabled={!this.state.canEdit || this.state.ag.presidentId}
                        />
                      </InputGroupItem>
                      <InputGroupItem>
                        <Label>Nom de l'option Contre</Label>
                        <Input
                          name="voteAgainstLabel"
                          disabled={!this.state.canEdit || this.state.ag.presidentId}
                        />
                      </InputGroupItem>
                      {values.canAbstain == "1" ? (
                        <InputGroupItem>
                          <Label>Nom de l'option Abstention</Label>
                          <Input
                            name="voteAbstainLabel"
                            disabled={!this.state.canEdit || this.state.ag.presidentId}
                          />
                        </InputGroupItem>
                      ) : null}
                    </InputGroup>
                    <InputGroup>
                      <InputGroupItem>
                        <Label>Nom de l'option supplémentaire (facultatif)</Label>
                        <Input
                          name="voteExtraOptionLabel"
                          disabled={!this.state.canEdit || this.state.ag.presidentId}
                        />
                      </InputGroupItem>
                    </InputGroup>
                  </>
                ) : null}
                <InputGroup>
                  <InputGroupItem>
                    <Label>Afficher le nombre de suffrages attendus</Label>
                    <Input
                      component="select"
                      name="showExpectedVoteCount"
                      disabled={!this.state.canEdit || this.state.ag.presidentId}
                    >
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                    </Input>
                  </InputGroupItem>
                  <InputGroupItem>
                    <Label>Afficher le nombre de suffrages réalisés</Label>
                    <Input
                      component="select"
                      name="showActualVoteCount"
                      disabled={!this.state.canEdit || this.state.ag.presidentId}
                    >
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                    </Input>
                  </InputGroupItem>
                  <InputGroupItem>
                    <Label>Afficher le nombre de non votés</Label>
                    <Input
                      component="select"
                      name="showNoVoteCount"
                      disabled={!this.state.canEdit || this.state.ag.presidentId}
                    >
                      <option value="1">Oui</option>
                      <option value="0">Non</option>
                    </Input>
                  </InputGroupItem>
                </InputGroup>
                <InputGroup>
                  {this.state.ag && this.state.ag.isAsynchronous == 0 ? (
                    <InputGroupItem>
                      <Label>Afficher le résultat du vote</Label>
                      <Input
                        component="select"
                        name="showVoteResult"
                        disabled={!this.state.canEdit || this.state.ag.presidentId}
                      >
                        <option value="1">Oui</option>
                        <option value="0">Non</option>
                      </Input>
                    </InputGroupItem>
                  ) : null}
                  <InputGroupItem>
                    <Label>Type de graphe</Label>
                    <Input
                      component="select"
                      name="resultGraphType"
                      disabled={!this.state.canEdit || this.state.ag.presidentId}
                    >
                      <option value="horizontalBars">Barres horizontales</option>
                      <option value="verticalBars">Barres verticales</option>
                      <option value="pieChart">Camembert</option>
                      <option value="table">Tableau</option>
                    </Input>
                  </InputGroupItem>
                  <InputGroupItem>
                    <Label>Afficher le résultat en</Label>
                    <Input
                      component="select"
                      name="resultDisplayType"
                      disabled={!this.state.canEdit || this.state.ag.presidentId}
                    >
                      <option value="voteCount">nombre de votes</option>
                      <option value="votePercent">pourcentage de votes</option>
                      <option value="both">nombre et pourcentage de votes</option>
                    </Input>
                  </InputGroupItem>
                </InputGroup>
                {values.voteType === "resolutionVote" && (
                  <>
                    <InputGroup>
                      <InputGroupItem>
                        <Label>Calcul du résultat</Label>
                        <Input
                          component="select"
                          name="majoriteCalculResultat"
                          disabled={!this.state.canEdit || this.state.ag.presidentId}
                        >
                          <option value="simple">Majorité simple</option>
                          <option value="renforcee">Majorité renforcée</option>
                        </Input>
                      </InputGroupItem>
                      {values.majoriteCalculResultat === "simple" && (
                        <InputGroupItem>
                          <Input
                            component="select"
                            name="typeSimple"
                            disabled={!this.state.canEdit || this.state.ag.presidentId}
                          >
                            <option value="sup50">> 50% des voix</option>
                            <option value="supEgal50">≥ 50% des voix</option>
                          </Input>
                        </InputGroupItem>
                      )}
                      {values.majoriteCalculResultat === "renforcee" && (
                        <>
                          <InputGroupItem>
                            <Input
                              component="select"
                              name="typeRenforcee"
                              disabled={!this.state.canEdit || this.state.ag.presidentId}
                            >
                              <option value="sup">> à</option>
                              <option value="supEgal">≥ à</option>
                            </Input>
                          </InputGroupItem>
                          <InputGroupItem>
                            <Input
                              type="number"
                              name="renforceePourcent"
                              disabled={!this.state.canEdit || this.state.ag.presidentId}
                            />
                          </InputGroupItem>
                          <div style={{ paddingBottom: "22px", fontWeight: "500" }}>%</div>
                        </>
                      )}
                      <InputGroupItem>
                        <Label>Afficher le résultat du calcul</Label>
                        <Input
                          component="select"
                          name="showCalculResultat"
                          disabled={!this.state.canEdit || this.state.ag.presidentId}
                        >
                          <option value="1">Oui</option>
                          <option value="0">Non</option>
                        </Input>
                      </InputGroupItem>
                    </InputGroup>
                    <InputGroup>
                      <InputGroupItem>
                        <Label>Base du calcul</Label>
                        <Input
                          component="select"
                          name="calculBaseTotal"
                          disabled={!this.state.canEdit || this.state.ag.presidentId}
                        >
                          <option value="all">Tous les votants attendus</option>
                          <option value="voters">
                            Tous les votants ayant voté (abstention comprise)
                          </option>
                          <option value="votersPasAbst">
                            Tous les votants ayant voté (abstention exclue)
                          </option>
                        </Input>
                      </InputGroupItem>
                    </InputGroup>
                  </>
                )}
                {values.voteType === "oneRoundElection" ||
                values.voteType === "twoRoundsElection" ||
                values.voteType === "plurinominalElection" ||
                values.voteType === "parityPlurinominalElection" ? (
                  <>
                    <Label>Personnalisation de libellés</Label>
                    <HorizontalSeparator marginVertical={8} />
                    <InputGroup>
                      {values.voteType === "parityPlurinominalElection" && (
                        <InputGroupItem>
                          <Label>Civilité</Label>
                          <Input
                            name="genderLabel"
                            disabled={ !this.state.canEdit || this.state.ag.presidentId }
                          />
                        </InputGroupItem>
                      )}
                      <InputGroupItem>
                        <Label>Nom</Label>
                        <Input
                          name="lastNameLabel"
                          disabled={!this.state.canEdit || this.state.ag.presidentId}
                        />
                      </InputGroupItem>
                      <InputGroupItem>
                        <Label>Prénom</Label>
                        <Input
                          name="firstNameLabel"
                          disabled={!this.state.canEdit || this.state.ag.presidentId}
                        />
                      </InputGroupItem>
                      <InputGroupItem>
                        <Label>Autres informations</Label>
                        <Input
                          name="infoLabel"
                          disabled={!this.state.canEdit || this.state.ag.presidentId}
                        />
                      </InputGroupItem>
                    </InputGroup>
                    <Label>Candidats</Label>
                    <HorizontalSeparator marginVertical={8} />
                    <Error name="candidates" component="div" />
                    <FieldArray
                      name="candidates"
                      render={(arrayHelpers) => (
                        <div style={{ alignItems: "flex-start" }}>
                          {values.candidates
                            ? values.candidates.map((candidate, index) => {
                                // Le dernier candidat est null, pourquoi ?
                                if (candidate) {
                                  return (
                                    <div key={index}>
                                      <InputGroup>
                                      {values.voteType ===
                                          "parityPlurinominalElection" && (
                                          <InputGroupItem>
                                            <Label>{values.genderLabel}*</Label>
                                            <Input
                                              component="select"
                                              name={`candidates.${index}.gender`}
                                              disabled={ !this.state.canEdit || this.state.ag.presidentId }
                                            >
                                              <option value="man" selected>Monsieur</option>
                                              <option value="woman">Madame</option>
                                          </Input>
                                          </InputGroupItem>
                                        )}
                                        <InputGroupItem>
                                          <Label>{values.lastNameLabel}</Label>
                                          <Input
                                            name={`candidates.${index}.lastname`}
                                            disabled={
                                              !this.state.canEdit || this.state.ag.presidentId
                                            }
                                          />
                                        </InputGroupItem>
                                        <InputGroupItem>
                                          <Label>{values.firstNameLabel}</Label>
                                          <Input
                                            name={`candidates.${index}.firstname`}
                                            disabled={
                                              !this.state.canEdit || this.state.ag.presidentId
                                            }
                                          />
                                        </InputGroupItem>
                                        <InputGroupItem>
                                          <Label>{values.infoLabel}</Label>
                                          <Input
                                            name={`candidates.${index}.info`}
                                            disabled={
                                              !this.state.canEdit || this.state.ag.presidentId
                                            }
                                          />
                                        </InputGroupItem>
                                        {this.state.canEdit && !this.state.ag.presidentId ? (
                                          <SquareButton
                                            type="button"
                                            onClick={() => arrayHelpers.remove(index) }
                                            style={{ margin: "12px 10px" }}
                                          >
                                            <i className="fal fa-trash" />
                                          </SquareButton>
                                        ) : null}
                                      </InputGroup>
                                    </div>
                                  );
                                } else {
                                  return null;
                                }
                              })
                            : null}
                          {this.state.canEdit && !this.state.ag.presidentId ? (
                            <SquareButton
                              type="button"
                              onClick={() =>
                                arrayHelpers.push({
                                  firstname: "",
                                  lastname: "",
                                  ...(values.voteType === 'parityPlurinominalElection' ? { gender: "man" } : {}),
                                  info: "",
                                })
                              }
                              style={{ display: "block" }}
                            >
                              <i className="fal fa-plus" />
                            </SquareButton>
                          ) : null}
                        </div>
                      )}
                    />

                    <Label>Candidats hors liste</Label>
                    <HorizontalSeparator marginVertical={8} />
                    {this.state.canEdit && !this.state.ag.presidentId ? (
                      <>
                        <Label>
                          Liste des candidats hors liste (format XLSX,{" "}
                          <a
                            href={values.voteType === "parityPlurinominalElection" ? "/Modèle liste candidats hors liste - élection paritaire.xlsx" : "/Modèle liste candidats hors liste.xlsx"}
                            download
                          >
                            télécharger le modèle
                          </a>
                          )
                        </Label>

                        <div
                          style={{
                            fontSize: "15px",
                            textAlign: "left",
                            marginBottom: "10px",
                          }}
                        >
                          {this.state.initialValues.offListCandidates &&
                          this.state.initialValues.offListCandidates.length > 0
                            ? `Vous avez déjà sélectionné un fichier contenant ${
                                this.state.initialValues.offListCandidates.length
                              } candidat(s) hors liste.
                          ${
                            this.state.canEdit
                              ? "Sélectionner un nouveau fichier remplacera tous les anciens candidats hors liste."
                              : ""
                          }`
                            : ""}
                        </div>
                        <Error name="off-list-candidates" component="div" />

                        <FileInput
                          name="off-list-candidates"
                          customOnChange={() => {
                            this.setState({ isFileUploading: true });
                          }}
                          onLoadingComplete={() => {
                            this.setState({ isFileUploading: false });
                          }}
                        />
                      </>
                    ) : null}
                  </>
                ) : null}
                {this.state.canEdit && !this.state.ag.presidentId && (
                  <div style={{ display: "flex" }}>
                    <Button
                      type="submit"
                      onClick={this.submit}
                      disabled={!isValid || this.state.isFileUploading || isSubmitting}
                    >
                      {this.props.edit ? "Valider" : "Ajouter"}
                    </Button>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        ) : null}
      </MediumContainer>
    );
  }
}

function upperCaseFirstLetter(str) {
  return str ? str[0].toUpperCase() + str.substr(1) : "";
}

export default VoteCreateOrEdit;
