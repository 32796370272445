import React from "react";
import { Editor, EditorState, RichUtils, Modifier } from "draft-js";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import CodeIcon from "@material-ui/icons/Code";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Divider } from "@material-ui/core";

export class EmailRichEditor extends React.Component {
	onChange = (editorState) => {
		this.props.onChange("content", editorState);
	};

	focus = () => this.refs.editor.focus();

	handleKeyCommand = (command) => {
		const { editorState } = this.props;
		const newState = RichUtils.handleKeyCommand(editorState, command);
		if (newState) {
			this.onChange(newState);
			return true;
		}
		return false;
	};

	onTab = (e) => {
		const maxDepth = 4;
		this.onChange(RichUtils.onTab(e, this.props.editorState, maxDepth));
	};
	toggleBlockType = (blockType) => {
		this.onChange(RichUtils.toggleBlockType(this.props.editorState, blockType));
	};
	toggleInlineStyle = (inlineStyle) => {
		this.onChange(
			RichUtils.toggleInlineStyle(this.props.editorState, inlineStyle)
		);
	};

	toggleColor = (toggledColor) => {
		const { editorState } = this.props;
		const selection = editorState.getSelection();

		// Let's just allow one color at a time. Turn off all active colors.
		const nextContentState = Object.keys(styleMap).reduce(
			(contentState, color) => {
				return Modifier.removeInlineStyle(contentState, selection, color);
			},
			editorState.getCurrentContent()
		);

		let nextEditorState = EditorState.push(
			editorState,
			nextContentState,
			"change-inline-style"
		);

		const currentStyle = editorState.getCurrentInlineStyle();

		// Unset style override for current color.
		if (selection.isCollapsed()) {
			nextEditorState = currentStyle.reduce((state, color) => {
				return RichUtils.toggleInlineStyle(state, color);
			}, nextEditorState);
		}

		// If the color is being toggled on, apply it.
		if (!currentStyle.has(toggledColor)) {
			nextEditorState = RichUtils.toggleInlineStyle(
				nextEditorState,
				toggledColor
			);
		}

		this.onChange(nextEditorState);
	};

	render() {
		const { editorState } = this.props;
		// If the user changes block type before entering any text, we can
		// either style the placeholder or hide it. Let's just hide it now.
		let className = "RichEditor-editor";
		var contentState = editorState.getCurrentContent();
		if (!contentState.hasText()) {
			if (contentState.getBlockMap().first().getType() !== "unstyled") {
				className += " RichEditor-hidePlaceholder";
			}
		}
		return (
			<div className="RichEditor-root">
				<div className="RichEditor-controls-container">
					<InlineStyleControls
						editorState={editorState}
						onToggle={this.toggleInlineStyle}
					/>

					<Divider
						orientation="vertical"
						flexItem
						style={{ marginRight: 16 }}
					/>

					<BlockStyleControls
						editorState={editorState}
						onToggle={this.toggleBlockType}
					/>
				</div>
				<div className="RichEditor-controls-container">
					<ColorControls
						editorState={editorState}
						onToggle={this.toggleColor}
					/>
				</div>

				<div className={className} onClick={this.focus}>
					<Editor
						blockStyleFn={getBlockStyle}
						customStyleMap={styleMap}
						editorState={editorState}
						handleKeyCommand={this.handleKeyCommand}
						onChange={this.onChange}
						onTab={this.onTab}
						placeholder=""
						ref="editor"
						spellCheck={true}
					/>
				</div>
			</div>
		);
	}
}
// Custom overrides for "code" style.
const styleMap = {
	CODE: {
		backgroundColor: "rgba(0, 0, 0, 0.05)",
		fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
		fontSize: 16,
		padding: 2,
	},
	"color-red": {
		color: "rgba(255, 0, 0, 1.0)",
	},
	"color-orange": {
		color: "rgba(255, 127, 0, 1.0)",
	},
	"color-yellow": {
		color: "rgba(180, 180, 0, 1.0)",
	},
	"color-green": {
		color: "rgba(0, 180, 0, 1.0)",
	},
	"color-blue": {
		color: "rgba(0, 0, 255, 1.0)",
	},
	"color-indigo": {
		color: "rgba(75, 0, 130, 1.0)",
	},
	"color-violet": {
		color: "rgba(127, 0, 255, 1.0)",
	},
	"color-black": {
		color: "#000",
	},
};

function getBlockStyle(block) {
	switch (block.getType()) {
		case "blockquote":
			return "RichEditor-blockquote";
		default:
			return null;
	}
}
class StyleButton extends React.Component {
	constructor() {
		super();
		this.onToggle = (e) => {
			e.preventDefault();
			this.props.onToggle(this.props.style);
		};
	}

	render() {
		let className = "RichEditor-styleButton";
		if (this.props.active) {
			className += " RichEditor-activeButton";
		}

		return (
			<span className={className} onMouseDown={this.onToggle}>
				{this.props.label === "B" ? (
					<FormatBoldIcon style={{ fontSize: 20 }} />
				) : this.props.label === "I" ? (
					<FormatItalicIcon style={{ fontSize: 20 }} />
				) : this.props.label === "U" ? (
					<FormatUnderlinedIcon style={{ fontSize: 20 }} />
				) : this.props.label === "CB" ? (
					<CodeIcon style={{ fontSize: 20 }} />
				) : this.props.label === "BQ" ? (
					<FormatQuoteIcon style={{ fontSize: 20 }} />
				) : this.props.label === "UL" ? (
					<FormatListBulletedIcon style={{ fontSize: 20 }} />
				) : this.props.label === "OL" ? (
					<FormatListNumberedIcon style={{ fontSize: 20 }} />
				) : this.props.label in styleMap ? (
					this.props.active ? (
						<CheckBoxIcon
							style={{
								fontSize: 20,
								...styleMap[this.props.label],
							}}
						/>
					) : (
						<CheckBoxOutlineBlankIcon
							style={{
								fontSize: 20,
								...styleMap[this.props.label],
							}}
						/>
					)
				) : (
					this.props.label
				)}
			</span>
		);
	}
}
const BLOCK_TYPES = [
	{ label: "BQ", style: "blockquote" },
	{ label: "UL", style: "unordered-list-item" },
	{ label: "OL", style: "ordered-list-item" },
];
const BlockStyleControls = (props) => {
	const { editorState } = props;
	const selection = editorState.getSelection();
	const blockType = editorState
		.getCurrentContent()
		.getBlockForKey(selection.getStartKey())
		.getType();
	return (
		<div className="RichEditor-controls">
			{BLOCK_TYPES.map((type) => (
				<StyleButton
					key={type.label}
					active={type.style === blockType}
					label={type.label}
					onToggle={props.onToggle}
					style={type.style}
				/>
			))}
		</div>
	);
};
var INLINE_STYLES = [
	{ label: "B", style: "BOLD" },
	{ label: "I", style: "ITALIC" },
	{ label: "U", style: "UNDERLINE" },
];
const InlineStyleControls = (props) => {
	var currentStyle = props.editorState.getCurrentInlineStyle();
	return (
		<div className="RichEditor-controls">
			{INLINE_STYLES.map((type) => (
				<StyleButton
					key={type.label}
					active={currentStyle.has(type.style)}
					label={type.label}
					onToggle={props.onToggle}
					style={type.style}
				/>
			))}
		</div>
	);
};

const COLORS = [
	{ label: "color-black", style: "color-black" },
	{ label: "color-red", style: "color-red" },
	{ label: "color-orange", style: "color-orange" },
	{ label: "color-yellow", style: "color-yellow" },
	{ label: "color-green", style: "color-green" },
	{ label: "color-blue", style: "color-blue" },
	{ label: "color-indigo", style: "color-indigo" },
	{ label: "color-violet", style: "color-violet" },
];

const ColorControls = (props) => {
	var currentStyle = props.editorState.getCurrentInlineStyle();

	return (
		<div className="RichEditor-controls">
			{COLORS.map((type) => (
				<StyleButton
					active={currentStyle.has(type.style)}
					label={type.label}
					onToggle={props.onToggle}
					style={type.style}
				/>
			))}
		</div>
	);
};
