import React from "react";
import styled from "styled-components";
import PlurinominalVoteResults from "./PlurinominalVoteResults";
import ParityPlurinominalVoteResults from "./ParityPlurinominalVoteResults";
import RoundBasedVoteResults from "./RoundBasedVoteResults";
import ResolutionVoteResults from "./ResolutionVoteResults";

const SmallTitle = styled.div`
  font-size: 24px;
  margin: 0 auto;
  margin-bottom: 15px;
  text-align: center;
`;

export default function VoteResults({ vote, printing, index }) {  
  if (vote.voteType === 'plurinominalElection') {
    return <PlurinominalVoteResults vote={vote} printing={printing} index={index} />
  }

  if (vote.voteType === 'parityPlurinominalElection') {
    return <ParityPlurinominalVoteResults vote={vote} printing={printing} index={index} />
  }

  if (vote.voteType === 'oneRoundElection' || vote.voteType === 'twoRoundsElection') {
    return <RoundBasedVoteResults vote={vote} printing={printing} index={index} />
  }

  if (vote.voteType === 'resolutionVote') {
    return <ResolutionVoteResults vote={vote} printing={printing} index={index} />
  }

  return (
    <SmallTitle style={{ textAlign: "center" }}>
      La ressource demandée n'existe pas.
    </SmallTitle>
  )
}
