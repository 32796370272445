import React from "react";
import { ChartContainer } from "../../utils/styled-components-library";
import styled from "styled-components";
import Chart from "./Chart";
import TableVoteResults from "./TableVoteResults";

const SmallTitle = styled.div`
  font-size: 24px;
  margin: 0 auto;
  margin-bottom: 15px;
  text-align: center;
`;

const TwoChartContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 10px;
`;

export default function ParityPlurinominalVoteResults({ vote, printing, index }) {

  const getAbstainCountValue = () => {
    const abstainVoteCount = vote.voteResults.filter((x) => x.optionType === 'voteAbstain').reduce((acc, cur) => acc + (cur.count || 0), 0);
    if (vote.resultDisplayType === "voteCount") {
      return `Nombre de vote(s) blanc(s) : ${abstainVoteCount}`;
    } 
    
    const abstainVotePercentage = abstainVoteCount === 0 ? '-' : parseFloat(
      (abstainVoteCount / (vote.useVotePowers ? vote.expectedVotesWithPowers : vote.expectedVotes)) * 100)
      .toFixed(2);
    if (vote.resultDisplayType === "votePercent") {
      return `Pourcentage de vote(s) blanc(s) : ${abstainVotePercentage}%`;
    } 
  
    return `Nombre et pourcentage de vote(s) blanc(s) : ${abstainVoteCount} (${abstainVotePercentage}%)`;
  }

  const sortedVoteResults = vote.voteResults.sort((a, b) => {
    return (b.count || 0) - (a.count || 0);
  });

  const castedVoteCount = vote.useVotePowers 
    ? vote.castedVotesWithPowers : vote.castedVotes;
  const nonCastedVoteCount = vote.useVotePowers
    ? vote.expectedVotesWithPowers - vote.castedVotesWithPowers
    : vote.expectedVotes - vote.castedVotes

  const womenVoteResults = sortedVoteResults.filter((x) => x.optionMetadata && JSON.parse(x.optionMetadata)?.gender === 'woman');
  const menVoteResults = sortedVoteResults.filter((x) =>  x.optionMetadata && JSON.parse(x.optionMetadata)?.gender === 'man');

  return (
    <ChartContainer
      key={vote.voteId}
      index={index}
      printing={printing}
    >
      <SmallTitle>{vote.voteName}</SmallTitle>
      <p>
        Nombre de suffrage(s) exprimé(s) : {castedVoteCount} <br />
        Nombre de non voté(s) : {nonCastedVoteCount} <br /><br />
      </p>

      <p style={{ fontWeight: 600 }}>{getAbstainCountValue()}</p>


      { vote.resultGraphType === "table" ? 
        (
          <TwoChartContainer>
             <TableVoteResults
              resultDisplayType={vote.resultDisplayType}
              title="Résultats Femmes"
              voteResults={womenVoteResults}
              voteType={vote.voteType}
              voteOriginalOptionIds={sortedVoteResults.map((x) => x.optionId)}
            />
            <TableVoteResults
              resultDisplayType={vote.resultDisplayType}
              title="Résultats Hommes"
              voteResults={menVoteResults}
              voteType={vote.voteType}
              voteOriginalOptionIds={sortedVoteResults.map((x) => x.optionId)}
            />
          </TwoChartContainer>
        ) : (
          <TwoChartContainer>
            <Chart
              chartType={vote.resultGraphType}
              title="Résultats Femmes"
              displayType={vote.resultDisplayType}
              data={womenVoteResults.map((x) => x.count)}
              labels={womenVoteResults.map((x) => `Mme ${x.optionName}`)}
              colors="#1D529C"
            />
            <Chart
              chartType={vote.resultGraphType}
              title="Résultats Hommes"
              displayType={vote.resultDisplayType}
              data={menVoteResults.map((x) => x.count)}
              labels={menVoteResults.map((x) => `Mr ${x.optionName}`)}
              colors="#1D529C"
            />
          </TwoChartContainer>
        )
      }
    </ChartContainer>
  )
}