import React from 'react'
import colors from '../constants/colors'
import { Button } from "../utils/styled-components-library";

export const VoteDetailList = ({ votes }) => {
    const [showVoteDetail, setShowVoteDetail] = React.useState(false)

    const groupedVotesByVoterId = votes.reduce((acc, curr) => {
        const voterId = curr.voterId
        if (!acc[voterId]) {
            acc[voterId] = []
        }
        acc[voterId].push(curr)
        return acc
    }, {})

    return (
        <>
            <Button white color='#ECF0F1' style={{ width: 250 }}onClick={() => setShowVoteDetail(!showVoteDetail)}>{showVoteDetail ? "Masquer" : "Voir"} le détail des votes</Button>
            {
                showVoteDetail && <>
                    <h3 >Détail des votes</h3>
                    <div style={{ display: "grid", gap: "8px", gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr)", gridAutoRows: "1fr" }}>
                        {Object.entries(groupedVotesByVoterId).sort(([_, a], [__, b]) => a[0].voterLastName.localeCompare(b[0].voterLastName)).map(([voterId, votes]) =>
                            <VoteDetailCard votes={votes} key={voterId} />
                        )}
                    </div>  </>
            }
        </>
    )
}

const getColor = (optionType) => {
    switch (optionType) {
        case 'voteFor':
            return colors.voteFor[1]
        case 'voteAgainst':
            return colors.voteAgainst[1]
        case 'voteExtraOption':
            return colors.voteExtraOption[1]
        case 'voteAbstain':
            return "#CCCCCC"
        default:
            return "#ECF0F1"
    }
}


export const VoteDetailCard = ({ votes }) => {
    return (
        <div style={{ backgroundColor: getColor(votes.length > 1 ? "voteFor" : votes[0].optionType), padding: 15, textAlign: 'left' }}>
            <div style={{ overflow: "hidden", textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontWeight: "600", marginBottom: 15 }}>{votes[0].voterFirstName} {votes[0].voterLastName}</div>
            {votes.map((vote) =>
                <div key={vote.optionId} style={{ marginBottom: 0 }}>{vote.optionName ?? "Pas de vote"}</div>
            )}
        </div>
    )

}