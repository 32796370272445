import React from "react";
import {
  Button,
  Input,
  Label,
  SmallContainer,
  Title,
  Error,
  TooltipContainer,
  TooltipList,
  TooltipListItem,
  MediumContainer,
} from "../utils/styled-components-library";
import { Formik, Form, Field } from "formik";
import API from "../utils/API";
import { DatePickerField } from "../components/DatePicker";
import FileInput from "../components/FileInput";
import moment from "moment";
import { Redirect } from "react-router-dom";
import AGMenu from "../components/AGMenu";
import ConfirmDestructiveModal from "../components/ConfirmDestructiveModal";
import Swal from "sweetalert2";
import { Tooltip, IconButton } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

const ROUNDING = 30 * 60 * 1000;
const defaultStartDate = moment(Math.ceil(+moment.utc() / ROUNDING) * ROUNDING);

let credentialsMailTemplateEmail =
  "Bonjour,\n\nLa session de vote commence le {{DATE_DEBUT}} et termine le {{DATE_FIN}}. Vos identifiants de connexion à ProVote sont les suivants :\n\n" +
  "Email : {{EMAIL}}\nMot de passe : {{MOT_DE_PASSE}}\nLien de connexion : {{LIEN_CONNEXION}}\n\n{{TEXTE_DELEGATION_POUVOIRS}}\n\n{{TEXTE_LIEN_PREREMPLI}}\n\nBien à vous,\nL'équipe ProVote";

let credentialsMailTemplateEmailSMS =
  "Bonjour,\n\nLa session de vote commence le {{DATE_DEBUT}} et termine le {{DATE_FIN}}. Vos identifiants de connexion à ProVote sont les suivants :\n\n" +
  "Email : {{EMAIL}}\nMot de passe : {{MOT_DE_PASSE}}\nLien de connexion : {{LIEN_CONNEXION}}\n\nPar ailleurs, vous avez reçu un SMS au {{NUMERO_TELEPHONE}}\n\n{{TEXTE_DELEGATION_POUVOIRS}}\n\n{{TEXTE_LIEN_PREREMPLI}}\n\nBien à vous,\nL'équipe ProVote";

class AGCreateOrEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: this.props.edit ? false : true,
      isDisabled: false,
      showConfirmCloseModal: false,
      showConfirmDeleteModal: false,
      isFileUploading: false,
    };
    if (this.props.edit) {
      this.loadAgData();
    } else {
      this.state.initialValues = {
        name: "",
        startDate: defaultStartDate,
        endDate: "",
        location: "",
        credentialsSendingType: "Email",
        voterList: "",
        multipleAttendance: 0,
        isAsynchronous: 0,
        attendance: "Pas d'émargement",
        sendDate: moment.utc(),
        firstReminderMinutes: null,
        secondReminderMinutes: null,
        canVotersDelegateVotes: 1,
        reminderAfterValue: null,
        reminderAfterUnit: "minutes",
        reminderBeforeValue: null,
        reminderBeforeUnit: "minutes",
        credentialsMailTemplate: credentialsMailTemplateEmail,
        maximumDelegatedPower: 0,
        allowVoteEditionAfterValidation: 1,
        allowPresidentToSeeResultsBeforeEnd: 1,
        addCredentialsToEmailLink: 1,

      };
    }
  }

  loadAgData() {
    API.get(
      "/agDetails",
      {
        agId: this.props.match.params.agId,
      },
      (ag) => {
        const attendance = ag.hasAttendance
          ? ag.signatureNeeded
            ? "Avec signature"
            : "Sans signature"
          : "Pas d'émargement";
        this.setState({
          isReady: true,
          isClosed: ag.isClosed,
          ag,
          initialValues: {
            name: ag.agName,
            startDate: moment(ag.agStartDate).isValid()
              ? moment.utc(ag.agStartDate)
              : defaultStartDate,
            endDate: moment(ag.agEndDate).isValid()
              ? moment.utc(ag.agEndDate)
              : null,
            location: ag.agLocation,
            credentialsSendingType: ag.credentialsSendingType,
            voterList: "",
            multipleAttendance: ag.multipleAttendance && ag.hasAttendance,
            isAsynchronous: ag.isAsynchronous,
            attendance,
            sendDate: moment(ag.sendDate).isValid()
              ? moment(ag.sendDate)
              : moment.utc(),
            firstReminderMinutes: ag.firstReminderMinutes,
            secondReminderMinutes: ag.secondReminderMinutes,
            canVotersDelegateVotes: ag.canVotersDelegateVotes,
            reminderAfterValue: ag.reminderAfterValue,
            reminderAfterUnit: ag.reminderAfterUnit || "minutes",
            reminderBeforeValue: ag.reminderBeforeValue,
            credentialsMailTemplate:
              ag.credentialsMailTemplate ||
              (ag.credentialsSendingType === "Email"
                ? credentialsMailTemplateEmail
                : ag.credentialsSendingType === "Email et SMS"
                ? credentialsMailTemplateEmailSMS
                : ""),
            reminderBeforeUnit: ag.reminderBeforeUnit || "minutes",
            maximumDelegatedPower: ag.maximumDelegatedPower || 0,
            allowVoteEditionAfterValidation: ag.allowVoteEditionAfterValidation,
            allowPresidentToSeeResultsBeforeEnd:
              ag.allowPresidentToSeeResultsBeforeEnd,
            addCredentialsToEmailLink: ag.addCredentialsToEmailLink,
          },
        });
      }
    );
  }

  closeAG(agId) {
    API.post("/createAg", { agId, close: true }, (data) => {
      if (data.success) {
        this.setState({ showConfirmCloseModal: false });
        this.loadAgData();
        Swal.fire({
          title: "Session de vote clôturée !",
          icon: "success",
          confirmButtonText: "Ok",
        });
      } else {
        this.setState({ showConfirmCloseModal: false });
        Swal.fire({
          title: "Impossible de clôturer la session de vote.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    });
  }

  // AG Deletion
  /* 	onAGDeleteHandler(agId, history) {
		API.post("/deleteAg", { agId }, (data) => {
			if (data.success) {
				this.setState({ showConfirmDeleteModal: false });
				Swal.fire({
					title: "Session de vote supprimée !",
					icon: "success",
					confirmButtonText: "Ok",
				}).then((ok) => {
					history.push("/");
				});
			} else {
				this.setState({ showConfirmDeleteModal: false });
				Swal.fire({
					title: "Impossible de supprimer la session de vote.",
					icon: "error",
					confirmButtonText: "Ok",
				});
			}
		});
	} */

  onCloseModalCloseHandler = () => {
    window.location.hash = "";
    this.setState({ showConfirmCloseModal: false });
  };

  onDeleteModalCloseHandler = () => {
    window.location.hash = "";
    this.setState({ showConfirmDeleteModal: false });
  };

  createTimeUnitSelector = (inputName, state) => {
    return (
      <Input component="select" name={inputName} disabled={state.isClosed}>
        {[
          { value: "minutes", display: "Minute(s)" },
          { value: "hours", display: "Heure(s)" },
          { value: "days", display: "Jour(s)" },
        ].map((x) => (
          <option key={x.value} value={x.value}>
            {x.display}
          </option>
        ))}
      </Input>
    );
  };

  createReminderInputs = (
    inputName,
    inputPlaceholder,
    unitSelectionName,
    state
  ) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Input
          name={inputName}
          disabled={state.isClosed}
          style={{ marginRight: "5px" }}
          placeholder={inputPlaceholder}
        />
        {this.createTimeUnitSelector(unitSelectionName, state)}
      </div>
    );
  };

  validateCredentialMailTemplatesVariables = (values) => {
    const errors = {};
    const mailTemplate = values.credentialsMailTemplate;

    errors.startDate = mailTemplate.includes("{{DATE_DEBUT}}");
    errors.endDate = mailTemplate.includes("{{DATE_FIN}");
    errors.email = mailTemplate.includes("{{EMAIL}}");
    errors.password = mailTemplate.includes("{{MOT_DE_PASSE}}");
    errors.link = mailTemplate.includes("{{LIEN_CONNEXION}}");
    errors.delegatedText = mailTemplate.includes(
      "{{TEXTE_DELEGATION_POUVOIRS}}"
    );
    errors.phone = mailTemplate.includes("{{NUMERO_TELEPHONE}}");

    return errors;
  };

  checkOrCrossIcon = (value) => {
    if (value) {
      return <CheckIcon style={{ paddingLeft: 5, fontSize: 14 }} />;
    } else {
      return <ClearIcon style={{ paddingLeft: 5, fontSize: 14 }} />;
    }
  };

  createCredentialsMailTemplateTooltip = (values) => {
    const errors = this.validateCredentialMailTemplatesVariables(values);

    return (
      <TooltipContainer>
        Variables Obligatoires :
        <TooltipList>
          <TooltipListItem isValid={errors.startDate}>
            {"{{DATE_DEBUT}}"} {this.checkOrCrossIcon(errors.startDate)}
          </TooltipListItem>
          <TooltipListItem isValid={errors.endDate}>
            {"{{DATE_FIN}}"} {this.checkOrCrossIcon(errors.endDate)}
          </TooltipListItem>
          <TooltipListItem isValid={errors.email}>
            {"{{EMAIL}}"} {this.checkOrCrossIcon(errors.email)}
          </TooltipListItem>
          <TooltipListItem isValid={errors.password}>
            {"{{MOT_DE_PASSE}}"} {this.checkOrCrossIcon(errors.password)}
          </TooltipListItem>
          <TooltipListItem isValid={errors.link}>
            {"{{LIEN_CONNEXION}}"} {this.checkOrCrossIcon(errors.link)}
          </TooltipListItem>
          <TooltipListItem isValid={errors.delegatedText}>
            {"{{TEXTE_DELEGATION_POUVOIRS}}"}{" "}
            {this.checkOrCrossIcon(errors.delegatedText)}
          </TooltipListItem>

          {values.credentialsSendingType === "Email et SMS" && (
            <TooltipListItem isValid={errors.phone}>
              {"{{NUMERO_TELEPHONE}}"} {this.checkOrCrossIcon(errors.phone)}
            </TooltipListItem>
          )}
        </TooltipList>
      </TooltipContainer>
    );
  };

  createCredentialsMailTemplateErrorMessage = (values) => {
    const errors = this.validateCredentialMailTemplatesVariables(values);

    const valid = Object.entries(errors).reduce((accumulator, currentValue) => {
      if (!accumulator) return false;
      else if (currentValue[0] === "phone" && !currentValue[1]) {
        return values.credentialsSendingType === "Email et SMS" ? false : true;
      } else if (!currentValue[1]) return false;
      else return true;
    }, true);

    return !valid ? (
      <>
        Variable(s) manquante(s)
        <ul>
          {!errors.startDate && <li>{"{{DATE_DEBUT}}"}</li>}
          {!errors.endDate && <li>{"{{DATE_FIN}}"}</li>}
          {!errors.email && <li>{"{{EMAIL}}"}</li>}
          {!errors.password && <li>{"{{MOT_DE_PASSE}}"}</li>}
          {!errors.link && <li>{"{{LIEN_CONNEXION}}"}</li>}
          {!errors.delegatedText && <li>{"{{TEXTE_DELEGATION_POUVOIRS}}"}</li>}
          {!errors.phone &&
            values.credentialsSendingType === "Email et SMS" && (
              <li>{"{{NUMERO_TELEPHONE}}"}</li>
            )}
        </ul>
      </>
    ) : null;
  };

  handleCredentialSendingTypeChange = (values, newValue, setFieldValue) => {
    if (values.credentialsSendingType === "Email")
      credentialsMailTemplateEmail = values.credentialsMailTemplate;
    else if (values.credentialsSendingType === "Email et SMS")
      credentialsMailTemplateEmailSMS = values.credentialsMailTemplate;

    if (newValue === "Email")
      setFieldValue("credentialsMailTemplate", credentialsMailTemplateEmail);
    else if (newValue === "Email et SMS")
      setFieldValue("credentialsMailTemplate", credentialsMailTemplateEmailSMS);
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} push />;
    }
    return (
      <>
        {this.props.edit && (
          <MediumContainer>
            <AGMenu activeTab="edit" ag={this.state.ag} {...this.props} />
          </MediumContainer>
        )}

        <SmallContainer>
          <Title>
            {this.props.edit
              ? "Modifier la session de vote"
              : "Créer une session de vote"}
          </Title>

          {this.state.isReady ? (
            <Formik
              initialValues={this.state.initialValues}
              validate={(values) => {
                const errors = {};

                if (!values.name) {
                  errors.name = "Requis";
                }
                if (!values.startDate) {
                  errors.startDate = "Requis";
                }
                if (!values.endDate) {
                  errors.endDate = "Requis";
                }
                if (values.maximumDelegatedPower < 0) {
                  errors.maximumDelegatedPower = "Value must be positive";
                }

                if (
                  values.credentialsSendingType === "Email" ||
                  values.credentialsSendingType === "Email et SMS"
                ) {
                  const credentialErrors =
                    this.createCredentialsMailTemplateErrorMessage(values);
                  if (credentialErrors) {
                    errors.credentialsMailTemplate = credentialErrors;
                  }
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                if (this.props.edit) {
                  API.post(
                    "/createAg",
                    {
                      ...values,
                      agId: this.props.match.params.agId,
                      edit: true,
                    },
                    (data) => {
                      setSubmitting(false);
                      if (data.success) {
                        this.setState({
                          redirect: `/ag/${this.props.match.params.agId}`,
                        });
                      } else {
                        setErrors({
                          voterList: data.error,
                        });
                      }
                    }
                  );
                } else {
                  API.post("/createAg", values, (data) => {
                    setSubmitting(false);
                    if (data.success) {
                      this.setState({ redirect: `/ag/${data.agId}#success` });
                    } else {
                      setErrors({
                        voterList: data.error,
                      });
                    }
                  });
                }
              }}
              validateOnMount
            >
              {({
                isSubmitting,
                isValid,
                values,
                setFieldValue,
                handleChange,
                validateField,
              }) => (
                <Form autoComplete="off">
                  {this.state.isClosed === 1 && (
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      Cette session de vote est clôturée et aucune modification
                      ne peut y être apportée.
                    </span>
                  )}
                  <Label>Nom*</Label>
                  <Input name="name" disabled={this.state.isClosed} />
                  <Error name="name" component="div" />

                  <Label>Date de début*</Label>
                  <DatePickerField
                    name="startDate"
                    withTime
                    disabled={this.state.isClosed}
                  />
                  <Error name="startDate" component="div" />

                  <Label>Date de fin*</Label>
                  <DatePickerField
                    name="endDate"
                    withTime
                    disabled={this.state.isClosed}
                  />
                  <Error name="endDate" component="div" />
                  <Label>Lieu</Label>
                  <Input name="location" disabled={this.state.isClosed} />
                  <Label>Mode d'envoi des identifiants aux votants</Label>
                  <Input
                    component="select"
                    name="credentialsSendingType"
                    disabled={this.state.isClosed}
                    onChange={(e) => {
                      handleChange(e);
                      this.handleCredentialSendingTypeChange(
                        values,
                        e.target.value,
                        setFieldValue
                      );
                      validateField("credentialsMailTemplate");
                    }}
                  >
                    {["Email", "Email et SMS", "SMS", "Courrier"].map((x) => (
                      <option key={x} value={x}>
                        {x}
                      </option>
                    ))}
                  </Input>

                  {(values.credentialsSendingType === "Email" ||
                    values.credentialsSendingType === "Email et SMS") && (
                    <>
                      <Label style={{ display: "flex", alignItems: "center" }}>
                        Mail d'envoi des identifiants de connexion
                        <Tooltip
                          title={this.createCredentialsMailTemplateTooltip(
                            values
                          )}
                        >
                          <IconButton
                            aria-label="delete"
                            style={{ marginLeft: 4 }}
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </Label>
                      <Input
                        name="credentialsMailTemplate"
                        disabled={this.state.isClosed}
                        component="textarea"
                        style={{ height: 200 }}
                      />
                      <Error name="credentialsMailTemplate" component="div" />
                      <div
                        style={{
                          fontSize: "12px",
                          textAlign: "left",
                          marginBottom: "10px",
                        }}
                      >
                        Vous pouvez modifier le mail qui sera envoyé aux
                        votants contenant leurs informations de connexion. Les
                        éléments contenus entre double accolade {'"{{}}"'}{" "}
                        seront remplacé par les informations de votre session de
                        vote.
                      </div>
                      <Label>
                    Préremplir les identifiants dans le lien de connexion
                  </Label>
                  <Input
                    component="select"
                    name="addCredentialsToEmailLink"
                    disabled={this.state.isClosed}
                  >
                    <option value="0">Non</option>
                    <option value="1">Oui</option>
                  </Input>
                    </>
                  )}

                  {values.credentialsSendingType != "Courrier" ? (
                    <>
                      <Label>Date d'envoi des identifiants</Label>
                      <DatePickerField
                        name="sendDate"
                        withTime
                        disabled={this.state.isClosed}
                      />
                    </>
                  ) : null}
                  <Label>
                    Envoi du mail de rappel <b>AVANT</b> l'ouverture des votes
                  </Label>
                  {this.createReminderInputs(
                    "reminderBeforeValue",
                    "Rappel AVANT",
                    "reminderBeforeUnit",
                    this.state
                  )}
                  <Label>
                    Envoi du mail de rappel <b>APRÈS</b> l'ouverture des votes
                  </Label>
                  {this.createReminderInputs(
                    "reminderAfterValue",
                    "Rappel APRÈS",
                    "reminderAfterUnit",
                    this.state
                  )}
                  {/* <div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<Input
											name="firstReminderMinutes"
											disabled={this.state.isClosed}
											style={{ marginRight: "5px" }}
											placeholder={"Premier rappel"}
										/>
										<div style={{ marginRight: "15px" }}>minutes</div>
										<Input
											name="secondReminderMinutes"
											disabled={this.state.isClosed}
											placeholder={"Second rappel"}
										/>
										<div style={{ marginLeft: "5px" }}>minutes</div>
									</div> */}
                  <Label>
                    Liste des votants (format XLSX,{" "}
                    <a href="/Modèle liste pour AG.xlsx" download>
                      télécharger le modèle
                    </a>
                    )
                  </Label>
                  <FileInput
                    name="voterList"
                    customOnChange={() => {
                      this.setState({ isFileUploading: true });
                    }}
                    onLoadingComplete={() => {
                      this.setState({ isFileUploading: false });
                    }}
                    disabled={this.state.isClosed}
                  />
                  <Error name="voterList" component="div" />
                  <div
                    style={{
                      fontSize: "12px",
                      textAlign: "left",
                      marginBottom: "10px",
                    }}
                  >
                    Vous pouvez ajouter des champs personnalisés dans le modèle
                    fourni et préciser leur remplissage obligatoire en terminant
                    le nom d'un champ par un astérisque *.
                  </div>
                  <Label>Nombre de pouvoir délégué maximum par votant</Label>
                  <Input type="number" name="maximumDelegatedPower" min="0" />
                  <div
                    style={{
                      fontSize: "12px",
                      textAlign: "left",
                      marginBottom: "10px",
                    }}
                  >
                    Mettez ce champ à 0 si vous ne souhaitez pas définir de
                    limite.
                  </div>
                  <Label>Type</Label>
                  <Input
                    component="select"
                    name="isAsynchronous"
                    disabled={this.state.isClosed}
                  >
                    <option value="0">Synchrone</option>
                    <option value="1">Asynchrone</option>
                  </Input>
                  <Label>
                    Possibilité pour les présidents de voir les résultats des
                    votes en cours (Asynchrone uniquement)
                  </Label>
                  <Input
                    component="select"
                    name="allowPresidentToSeeResultsBeforeEnd"
                    disabled={this.state.isClosed}
                  >
                    <option value="0">Non</option>
                    <option value="1">Oui</option>
                  </Input>
                  <Label>Émargement</Label>
                  <Input
                    disabled={this.state.isClosed}
                    component="select"
                    name="attendance"
                    onChange={(e) => {
                      setFieldValue("attendance", e.target.value);
                      const multipleAttendance =
                        e.target.value !== "Pas d'émargement" &&
                        values.multipleAttendance;
                      setFieldValue("multipleAttendance", multipleAttendance);
                    }}
                  >
                    {[
                      "Pas d'émargement",
                      "Avec signature",
                      "Sans signature",
                    ].map((x) => (
                      <option key={x} value={x}>
                        {x}
                      </option>
                    ))}
                  </Input>
                  <Label>
                    Possibilité pour les votants de modifier un vote validé
                  </Label>
                  <Input
                    component="select"
                    name="allowVoteEditionAfterValidation"
                    disabled={this.state.isClosed}
                  >
                    <option value="0">Non</option>
                    <option value="1">Oui</option>
                  </Input>
                  <Label>
                    Possibilité pour les votants de déléguer leur pouvoir depuis
                    l'interface
                  </Label>
                  <Input
                    component="select"
                    name="canVotersDelegateVotes"
                    disabled={this.state.isClosed}
                  >
                    <option value="0">Non</option>
                    <option value="1">Oui</option>
                  </Input>
                  {values.attendance !== "Pas d'émargement" && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <Field
                        disabled={this.state.isClosed}
                        name="multipleAttendance"
                        type="checkbox"
                        id="checkboxAttendance"
                        checked={!!values.multipleAttendance}
                        value={
                          values.attendance !== "Pas d'émargement" &&
                          !!values.multipleAttendance
                        }
                      />
                      <label
                        style={{ cursor: "pointer", padding: "5px" }}
                        htmlFor="checkboxAttendance"
                      >
                        Emargement multiple
                      </label>
                    </div>
                  )}
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={() => this.setState({ redirect: "/" })}
                      style={{ marginRight: 6 }}
                    >
                      Retour
                    </Button>
                    <Button
                      type="submit"
                      onClick={this.submit}
                      disabled={
                        !isValid ||
                        isSubmitting ||
                        this.state.isFileUploading ||
                        this.state.isClosed
                      }
                    >
                      {this.props.edit ? "Valider" : "Créer"}
                    </Button>
                  </div>
                  {this.props.edit && !this.state.isClosed && (
                    <>
                      <Label
                        style={{
                          color: "red",
                          marginTop: 20,
                          fontWeight: "bold",
                        }}
                      >
                        Zone de danger
                      </Label>
                      <Button
                        type="button"
                        onClick={() =>
                          this.setState({ showConfirmCloseModal: true })
                        }
                        style={{ backgroundColor: "red", marginTop: "20px" }}
                      >
                        Clôturer la session de vote
                      </Button>
                    </>
                  )}
                  {/* AG Deletion */}
                  {/* <Button
										type="button"
										onClick={() =>
											this.setState({ showConfirmDeleteModal: true })
										}
										style={{ backgroundColor: "red", marginTop: "20px" }}
									>
										Supprimer la session de vote
									</Button> */}
                </Form>
              )}
            </Formik>
          ) : null}
        </SmallContainer>

        {this.state.showConfirmCloseModal && (
          <ConfirmDestructiveModal
            onCloseHandler={this.onCloseModalCloseHandler}
            message="Êtes-vous sûr(e) de vouloir clôturer cette session de vote ? Cette
					action est irréversible."
            confirmButtonText="Clôturer"
            confirmButtonHandler={() =>
              this.closeAG(this.props.match.params.agId)
            }
          />
        )}

        {/* AG Deletion */}
        {/* {this.state.showConfirmDeleteModal && (
					<ConfirmDestructiveModal
						onCloseHandler={this.onDeleteModalCloseHandler}
						message="Êtes-vous sûr(e) de vouloir supprimer cette session de vote ? Cette
					action est irréversible."
						confirmButtonText="Supprimer"
						confirmButtonHandler={() =>
							this.onAGDeleteHandler(this.props.match.params.agId, history)
						}
					/>
				)} */}
      </>
    );
  }
}

export default AGCreateOrEdit;
